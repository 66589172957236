import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../../components/mainLayout";
import Navigation from "../../components/mainLayout/navigation";
import BackToAttendance from "../../components/userProfile/backToAttendance";
import UserImage from "../../components/userProfile/userImage";
import UserInfo from "../../components/userProfile/userInfo";
import UserModal from "../../components/userProfile/userModal";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../context/auth";

function UserProfile() {
  const { getMyInfo } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Helmet>
        <title>Profile – Personal Information – AAMAX</title>
      </Helmet>
      {/*====================== Start Dashboard ======================*/}
      <section className="ams-dashboard">
        <MainLayout />
        {/*.navbar*/}
        <div className="container-fluid p-0">
          <Navigation />
          {/*.sidebar*/}
          <main className="page-content-wrapper">
            <div className="page-content-inner">
              <div className="page-header d-flex justify-content-between flex-column flex-sm-row align-items-center py-3 mb-lg-2">
                <h4 className="my-2">Personal Information</h4>
              </div>
              {/*.page-header*/}
              <div className="row">
                <div className="col-12">
                  <div className="card-wrapper">
                    <UserImage />
                    {/*.card-header*/}
                    <div className="card-body position-relative p-3 p-md-4">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="edit-icon position-absolute top-0 end-0"
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModal"
                        onClick={() => setVisible(true)}
                      >
                        <i className="bi bi-pencil-fill" />
                      </a>
                      <UserInfo />
                      {/* Modal */}
                      <UserModal visible={visible} setVisible={setVisible} />
                    </div>
                    {/*.card-body*/}
                    <BackToAttendance />
                    {/*.card-footer*/}
                  </div>
                  {/*.card-wrapper*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.page-content-inner*/}
          </main>
          {/*.page-content-wrapper*/}
        </div>
        {/*.container-fluid*/}
      </section>
      {/*====================== End Dashboard ======================*/}
    </div>
  );
}

export default UserProfile;
