import React, { useContext } from "react";
import LogoSection from "../../components/login/logoSection";
import LoginSection from "../../components/login/loginSection";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { Helmet } from "react-helmet";

function Home() {
  const token = localStorage.getItem("token");
  const { isAuthenticated, loading, user } = useContext(AuthContext);

  if ((token || isAuthenticated) && !loading) {
    if (user.role === "employee") {
      return <Navigate to={"/attendance"} />;
    }
    if (user.role === "company" || user.role === "manager") {
      return <Navigate to={"/dashboard"} />;
    }
    if (user.role === "superAdmin") {
      return <Navigate to={"/admin/dashboard"} />;
    }
  } else if (token && loading) {
    return <></>;
  }
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <Helmet>
        <title>Login – AAMAX</title>
      </Helmet>
      {/*====================== Start Sign in Form ======================*/}
      <section className="ams-login col-overlay py-5">
        <div className="container h-100">
          <div className="form-conainer-wrapper">
            <div className="form-container-inner shadow rounded-4 row gx-0 overflow-hidden w-100">
              <LogoSection />
              <LoginSection />
            </div>
            {/*.row*/}
          </div>
          {/*.form-conainer-wrapper*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </div>
  );
}

export default Home;
