import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SellectDate(props) {
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  return (
    <>
      <div className="card-header p-3 p-md-4 pb-md-2">
        <div className="row">
          <div className="col-sm-5 mb-3">
            <div className="date-form">
              <DatePicker
                placeholderText="Pick a start date"
                selected={startingDate}
                onChange={(date) => setStartingDate(date)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-sm-5 mb-3">
            <div className="date-form">
              <DatePicker
                placeholderText="Pick an end date"
                selected={endingDate}
                onChange={(date) => setEndingDate(date)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-sm-2 mb-3">
            <button
              className="btn primary-btn-two shadow"
              onClick={() => props.onSubmit(startingDate, endingDate)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellectDate;
