import { message, Popconfirm } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { DELETE_USER_URL } from "../../endPoints";
import { Link } from "react-router-dom";
import { AttContext } from "../../context/attContext";
import DashboardSkeleton from "../dashboard/dashboardSkeleton";
import { AuthContext } from "../../context/auth";

function EmployeesTable({
  data,
  setEditData,
  setEditVisible,
  getData,
  searchEmpl,
  loading,
  page_size,
  nextPageCount,
}) {
  const { setEmployeeId } = useContext(AttContext);
  const { permissionOnlyAdmin } = useContext(AuthContext);

  const confirm = (id) => {
    axios
      .delete(`${DELETE_USER_URL}/${id}`)
      .then(() => {
        getData();
        message.success("Employee profile deleted successfully.");
      })

      .catch((err) => {
        message.warning("Failed to delete employee profile. Please try again.");
        console.error(err);
      });
  };

  const filteredEmployees = data.filter(
    (empl) =>
      empl.name.toLowerCase().includes(searchEmpl.toLowerCase()) ||
      empl.email.toLowerCase().includes(searchEmpl.toLowerCase())
  );

  const handleSetEmployeeId = (id) => {
    setEmployeeId(id);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover emp-attend-list">
          <thead>
            <tr>
              <th scope="col" className="img-col">
                Photo
              </th>
              <th scope="col">Name</th>
              <th scope="col">Designation</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Email</th>
              <th scope="col">Salary</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <DashboardSkeleton
                withImg
                length={nextPageCount === 0 ? page_size : nextPageCount}
              />
            ) : (
              filteredEmployees.map((a) => {
                return (
                  <tr key={a._id}>
                    <td>
                      <img
                        src={a?.userId?.profile?.imageUrl}
                        className="table-img fit-user-img"
                        alt="user img"
                      />
                    </td>
                    <td>{a?.name}</td>
                    <td>{a?.designation}</td>
                    <td>{a?.phoneNumber}</td>
                    <td>{a?.email}</td>
                    <td>{a?.salary}</td>
                    <td>
                      {a?.status === "active" ? (
                        <span className="d-inline-block badge green-col">
                          Active
                        </span>
                      ) : (
                        <span className="d-inline-block badge red-col">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>
                      {permissionOnlyAdmin && (
                        <>
                          <button
                            className="tb-action edit"
                            onClick={() => {
                              setEditVisible(true);
                              setEditData(a);
                            }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#editEmployeeModal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={18}
                              height={18}
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </button>
                          <Popconfirm
                            title="Confirm Delete"
                            description="Are you sure you want to delete this employee?"
                            onConfirm={() => confirm(a.userId._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button className="tb-action delete">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                fill="currentColor"
                                className="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                              </svg>
                            </button>
                          </Popconfirm>
                        </>
                      )}
                      <Link
                        to="/employee-attendance"
                        className="d-inline-flex v-att-action"
                        onClick={() => handleSetEmployeeId(a._id)}
                      >
                        <span className="align-text-bottom me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                            />
                          </svg>
                        </span>{" "}
                        Attendance
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EmployeesTable;
