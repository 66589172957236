import React, { useContext, useEffect, useState } from "react";
import AttendanceRecordDetail from "../../components/attendanceRecord/attendanceRecordDetail";
import RecordWorkingDetail from "../../components/attendanceRecord/recordWorkingDetail";
import MainLayout from "../../components/mainLayout";
import Navigation from "../../components/mainLayout/navigation";
import SellectDate from "../../components/attendanceRecord/sellectDate";
import axios from "axios";
import { USER_ATTENDANCE_URL } from "../../endPoints";
import { AuthContext } from "../../context/auth";
import { getTodayDate } from "../../util/todayDate";
import { DateContext } from "../../context/dateContext";
import { Helmet } from "react-helmet";
import moment from "moment";
import { message } from "antd";

function AttendanceRecord() {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [attendances, setAttendances] = useState([]);
  const [attendanceRecord, setAttendanceRecord] = useState([]);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);

  const getAttendance = () => {
    axios.get(USER_ATTENDANCE_URL).then((res) => {
      setAttendances(res.data.data);
    });
  };

  const todayDate = getTodayDate();

  const filterAttendanceRecord = (start, end) => {
    const filteredAttendance = attendances?.filter((attendance) => {
      const date = new Date(attendance.date);
      return (
        moment(date).isSameOrAfter(start, "day") &&
        moment(date).isSameOrBefore(end, "day")
      );
    });
    setAttendanceRecord(filteredAttendance);
  };

  useEffect(() => {
    if (isAuthenticated) {
    }
    getAttendance();
  }, [user]);

  return (
    <div>
      <Helmet>
        <title>Attendance History – AAMAX</title>
      </Helmet>
      {/*====================== Start Dashboard ======================*/}
      <section className="ams-dashboard">
        <MainLayout />
        {/*.navbar*/}
        <div className="container-fluid p-0">
          <Navigation />
          {/*.sidebar*/}
          <main className="page-content-wrapper">
            <div className="page-content-inner">
              <div className="page-header d-flex justify-content-between flex-column flex-sm-row align-items-center py-3 mb-lg-2">
                <h4 className="my-2">Attendance History</h4>
                <div className="btn-toolbar my-2">
                  <h3 className="hd-date m-0">
                    Date : <small className="text-muted">{todayDate}</small>
                  </h3>
                </div>
              </div>
              {/*.page-header*/}
              <div className="row">
                <div className="col-12">
                  <div className="card-wrapper">
                    <DateContext.Provider value={{ startingDate, endingDate }}>
                      <SellectDate
                        onSubmit={(start, end) => {
                          if (!start || !end)
                            return message.warning(
                              "Incomplete date range. Please enter both dates."
                            );
                          if (start > end || !start || !end)
                            return message.warning(
                              "The start date must be before or equal to the end date."
                            );
                          setStartingDate(start);
                          setEndingDate(end);
                          filterAttendanceRecord(start, end);
                        }}
                      />
                      {/*.card-header*/}
                      <AttendanceRecordDetail attendances={attendanceRecord} />
                      {/*.card-body*/}
                      <RecordWorkingDetail
                        attendances={attendances}
                        attendanceRecord={attendanceRecord}
                      />
                    </DateContext.Provider>
                    {/*.card-footer*/}
                  </div>
                  {/*.card-wrapper*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.page-content-inner*/}
          </main>
          {/*.page-content-wrapper*/}
        </div>
        {/*.container-fluid*/}
      </section>
      {/*====================== End Dashboard ======================*/}
    </div>
  );
}

export default AttendanceRecord;
