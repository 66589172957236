import React, { useContext } from "react";
import { AttContext } from "../../context/attContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchField from "./searchField";

function EmployeeInfo() {
  const { userData, selectedMonth, setSelectedMonth } = useContext(AttContext);

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
  };

  return (
    <>
      <div className="col-12">
        <div className="card-wrapper bg-white overflow-visible min-h-auto position-relative rounded-3 mb-4">
          <div className="card-body p-3 p-md-4">
            <div className="row filter-row">
              <div className="col-md col-lg">
                <div className="form-group mb-3">
                  <SearchField placeholder="Search by Name" />
                </div>
              </div>
              {/*.col-grid*/}
              <div className="col-md col-lg">
                <div className="form-group mb-3">
                  <SearchField placeholder="Search by Email" />
                </div>
              </div>
              {/*.col-grid*/}
              <div className="col-md col-lg">
                <div className="form-group mb-3">
                  <DatePicker
                    className="form-control"
                    placeholder="Select Month"
                    selected={selectedMonth}
                    onChange={handleMonthChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </div>
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
            <div className="row align-items-center">
              <div className="col-md col-lg my-3 my-lg-2 text-md-start text-center">
                <div className="user-about d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start">
                  <div className="img-wrapper">
                    {console.log(userData)}
                    {userData?.userId?.profile?.imageUrl ? (
                      <img
                        src={userData?.userId?.profile?.imageUrl}
                        className="filter-emp-img me-md-3 mb-3 mb-md-0 fit-user-img"
                        alt="Admin"
                      />
                    ) : (
                      <img
                        src={"assets/images/logo.png"}
                        className="filter-emp-img me-md-3 mb-3 mb-md-0 fit-user-img"
                        alt="Admin"
                      />
                    )}
                  </div>
                  <div className="emp-info-wrapper">
                    <h6 className="mb-0 fw-semibold">
                      {userData?.name || "Name"}
                    </h6>
                    <small className="text-muted">
                      {userData?.designation || "Designation"}
                    </small>
                  </div>
                </div>
                {/*.user-about*/}
              </div>
              {/*.col-grid*/}
              <div className="col-md col-lg my-3 my-lg-2 text-md-start text-center">
                <div className="user-about">
                  <h6 className="mb-0 fw-semibold">Employee Email</h6>
                  <small className="text-muted">{userData?.email}</small>
                </div>
                {/*.user-about*/}
              </div>
              {/*.col-grid*/}
              <div className="col-md col-lg my-3 my-lg-2 text-md-start text-center">
                <div className="user-about">
                  <h6 className="mb-0 fw-semibold">Month</h6>
                  <small className="text-muted">
                    {selectedMonth.toLocaleString("default", { month: "long" })}{" "}
                    {selectedMonth.getFullYear()}
                  </small>
                </div>
                {/*.user-about*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.card-body*/}
        </div>
        {/*.card-wrapper*/}
      </div>
    </>
  );
}

export default EmployeeInfo;
