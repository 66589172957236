import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/mainLayout/adminLayout";
import SearchNAddEmployee from "../../components/employees/searchNAddEmployee";
import Pagination from "../../components/dashboard/pagination";
import { ADMIN_DELETED_COMPANIES_URL } from "../../endPoints";
import axios from "axios";
import { Helmet } from "react-helmet";
import SuperAdminNavigation from "../../components/mainLayout/navigation/superAdminNavigation";
import DeletedCompaniesTable from "../../components/superAdmin/deletedCompaniesTable";

const DeletedCompanies = () => {
  //   const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  //   const [editVisible, setEditVisible] = useState(false);
  //   const [editData, setEditData] = useState({});
  const [data, setData] = useState([]);
  const [searchEmpl, setSearchEmpl] = useState("");
  const page_size = 10;
  const [nextPageCount, setNextPageCount] = useState(page_size);
  const [lastPageItemsCount, setLastPageItemsCount] = useState(page_size);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const getData = () => {
    setLoading(true);
    axios
      .get(
        ADMIN_DELETED_COMPANIES_URL +
          `?currentPage=${paginationData.currentPage}&pageSize=${page_size}`
      )
      .then((res) => {
        setData(res.data.data);
        setLastPageItemsCount(res.data.meta.lastPageItemsCount);
        setNextPageCount(res.data.meta.nextPageCount);
        setPaginationData({
          currentPage: res.data.meta.currentPage,
          totalPages: res.data.meta.totalPages,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData.currentPage]);
  return (
    <>
      <div>
        <Helmet>
          <title>Team Members - AAMAX</title>
        </Helmet>
        {/*====================== Start Dashboard ======================*/}
        <section className="ams-dashboard admin-dashboard">
          <AdminLayout />
          {/*.navbar*/}
          <div className="container-fluid p-0">
            <SuperAdminNavigation />
            {/*.sidebar*/}
            <main className="page-content-wrapper">
              <div className="page-content-inner">
                <div className="page-header text-center text-md-start py-3 mb-lg-2">
                  <h4 className="my-2">Team Members</h4>
                </div>
                {/*.page-header*/}
                <div className="row">
                  <div className="col-12">
                    <div className="card-wrapper">
                      <div className="card-header m-3 m-md-4 mb-md-0 mb-0 bg-light">
                        <div className="row">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="d-flex align-items-center">
                              <SearchNAddEmployee
                                searchEmpl={searchEmpl}
                                setSearchEmpl={setSearchEmpl}
                                noAdd
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*.card-header*/}
                      <div className="card-body p-3 p-md-4 pt-md-0 pt-0">
                        <DeletedCompaniesTable
                          nextPageCount={nextPageCount}
                          page_size={page_size}
                          loading={loading}
                          getData={getData}
                          data={data}
                          searchEmpl={searchEmpl}
                        />
                      </div>
                      {/*.card-body*/}

                      <Pagination
                        lastPageItemsCount={lastPageItemsCount}
                        pageSize={page_size}
                        setNextPageCount={setNextPageCount}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                      />
                      {/*.card-footer*/}
                    </div>
                    {/*.card-wrapper*/}
                  </div>
                  {/*.col-grid*/}
                </div>
                {/*.row*/}
              </div>
              {/*.page-content-inner*/}
            </main>
            {/*.page-content-wrapper*/}
          </div>
          {/*.container-fluid*/}
        </section>
        {/*====================== End Dashboard ======================*/}
      </div>
    </>
  );
};

export default DeletedCompanies;
