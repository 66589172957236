import React, { useContext } from "react";
import { AuthContext } from "../../context/auth";

function UserInfo() {
  const { info } = useContext(AuthContext);
  console.log(info);
  return (
    <>
      <ul className="user-info">
        <li>
          <div className="title">Full Name</div>
          <div className="text">{info?.name}</div>
        </li>
        <li>
          <div className="title">Email</div>
          <div className="text">{info?.email}</div>
        </li>
        {/* <li>
          <div className="title">Passwrod</div>
          <div className="text">pakistan123</div>
        </li> */}
        <li>
          <div className="title">Phone Number</div>
          <div className="text">{info?.phoneNumber}</div>
        </li>
        <li>
          <div className="title">Designation</div>
          <div className="text">{info?.designation}</div>
        </li>
      </ul>
    </>
  );
}

export default UserInfo;
