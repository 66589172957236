import React, { createContext, useState } from "react";
import { DELETE_IMAGE_URL, GET_IMAGE_URL } from "../endPoints";
import axios from "axios";

const ImageContext = createContext();

const ImageContextProvider = ({ children }) => {
  const [profileImage, setProfileImage] = useState("");

  const deleteImage = () => {
    axios.delete(DELETE_IMAGE_URL).then(() => {
      setProfileImage("");
    });
  };

  const getImage = () => {
    axios
      .get(GET_IMAGE_URL)
      .then((response) => {
        setProfileImage(response?.data?.imageUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ImageContext.Provider
      value={{
        profileImage,
        deleteImage,
        getImage,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export { ImageContextProvider, ImageContext };
