import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import { Navigate, useSearchParams } from "react-router-dom";
import LogoSection from "../../components/login/logoSection";
import ResetPassword from "../../components/updatePassword/resetPassword";
import axios from "axios";
import { VERFIY_LINK_TOKEN_URL } from "../../endPoints";
import { Helmet } from "react-helmet";
import PageNotFound from "../404PageNotFound";

function UpdatePassword() {
  const [searchParams] = useSearchParams();
  const link_token = searchParams.get("token");
  const token = localStorage.getItem("token");
  const { isAuthenticated } = useContext(AuthContext);

  const [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const t = (to) => {
    setLoading(true);
    axios
      .post(VERFIY_LINK_TOKEN_URL, {
        token: to,
      })
      .then((res) => {
        setAccess(res.data.link_alive);
        setLoading(false);
      })
      .catch((err) => {
        setAccess(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (link_token) {
      t(link_token);
    }
  }, [link_token]);

  if (token || isAuthenticated) {
    return <Navigate to={"/"} />;
  } else if (token || loading) {
    return <></>;
  }
  if (!access && !loading) return <PageNotFound />;
  return (
    <div>
      <Helmet>
        <title>Reset Password - AAMAX</title>
      </Helmet>
      {/*====================== Start New Password Form ======================*/}
      <section className="ams-login col-overlay py-sm-5 py-3">
        <div className="container h-100">
          <div className="form-conainer-wrapper">
            <div className="form-container-inner shadow rounded-4 row gx-0 overflow-hidden w-100">
              <LogoSection />
              <ResetPassword token={access && link_token} />
            </div>
            {/*.row*/}
          </div>
          {/*.form-conainer-wrapper*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Forgot Form ======================*/}
    </div>
  );
}

export default UpdatePassword;
