import moment from "moment";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { message, TimePicker } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  UPDATE_ATTENDANCE_LEAVE_URL,
  UPDATE_ATTENDANCE_URL,
} from "../../endPoints";

const EditAttendanceModal = ({
  visible,
  setVisible,
  data,
  attId,
  getData,
  leave,
}) => {
  const [loading, setLoading] = useState(false);

  const [editData, setEditData] = useState({
    checkIn: "",
    checkOut: "",
    breaks: [{ start: "", end: "" }],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      checkIn: moment(editData.checkIn).format("YYYY-MM-DD HH:mm:ss"),
      checkOut: moment(editData.checkOut).format("YYYY-MM-DD HH:mm:ss"),
      breaks: editData.breaks.map((breakData) => ({
        start: moment(breakData.start).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(breakData.end).format("YYYY-MM-DD HH:mm:ss"),
      })),
    };

    if (leave) {
      try {
        setLoading(true);

        const response = await axios.put(
          `${UPDATE_ATTENDANCE_LEAVE_URL}/${attId}`,
          updatedData
        );
        if (response.status === 200) {
          getData();
          setTimeout(() => {
            setVisible(false);
            setLoading(false);
          }, 500);
          message.success("Edit successful. Attendance information updated.");
        } else {
          message.warning(
            "Failed to update attendance information. Please try again."
          );
        }
      } catch (error) {
        message.warning(
          "Failed to update attendance information. Please try again."
        );
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);

        const response = await axios.put(
          `${UPDATE_ATTENDANCE_URL}/${attId}`,
          updatedData
        );
        if (response.status === 200) {
          getData();
          setTimeout(() => {
            setVisible(false);
            setLoading(false);
          }, 500);
          message.success("Edit successful. Attendance information updated.");
        } else {
          message.warning(
            "Failed to update attendance information. Please try again."
          );
        }
      } catch (error) {
        message.warning(
          "Failed to update attendance information. Please try again."
        );
        setLoading(false);
      }
    }
  };

  const addBreak = (e) => {
    e.preventDefault();
    setEditData((prevState) => {
      const updatedBreaks = {
        start: moment(editData.checkIn).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(editData.checkOut).format("YYYY-MM-DD HH:mm:ss"),
      };
      return { ...prevState, breaks: [...prevState.breaks, updatedBreaks] };
    });
  };
  const removeBreak = (e, ind) => {
    e.preventDefault();
    setEditData((prevState) => {
      const prevBreak = [...prevState.breaks];

      const updatedBreaks = prevBreak.filter((_, i) => i !== ind);
      console.log(updatedBreaks);
      return { ...prevState, breaks: updatedBreaks };
    });
  };

  useEffect(() => {
    if (data && data.breaks) {
      setEditData({
        checkIn: moment(data.checkIn).format("YYYY-MM-DD HH:mm:ss"),
        checkOut: moment(data.checkOut).format("YYYY-MM-DD HH:mm:ss"),
        breaks: data.breaks.map((breakData) => ({
          start: moment(breakData.start).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(breakData.end).format("YYYY-MM-DD HH:mm:ss"),
        })),
      });
    }
  }, [data]);

  return (
    <>
      <div
        className={`modal fade ${visible && "show"}`}
        id="exampleModal"
        tabIndex={-1}
        style={{ display: `${visible ? "block" : "none"}` }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content px-4 py-md-5 py-4">
            <div className="modal-header text-center d-block py-0 border-0 pb-3">
              <h1
                className="modal-title fs-5 mb-md-4 mb-2"
                id="exampleModalLabel"
              >
                Edit Attendance Information
              </h1>
              <button
                type="button"
                onClick={() => {
                  setVisible(false);
                }}
                className="btn-close position-absolute top-0 end-0"
              />
            </div>
            <div className="modal-body p-0">
              <form className="row g-3 user-edit-form">
                <div className="col-md-6">
                  <label htmlFor="CheckIn" className="form-label">
                    Check In
                  </label>
                  {}
                  <TimePicker
                    format="HH:mm A"
                    className="form-control"
                    id="checkIn"
                    value={moment(editData.checkIn)}
                    onSelect={(time) =>
                      setEditData((prevState) => ({
                        ...prevState,
                        checkIn: moment(editData.checkIn)
                          .set({
                            hour: time.hour(),
                            minute: time.minute(),
                            second: time.second(),
                            millisecond: time.millisecond(),
                          })
                          .format("YYYY-MM-DD HH:mm:ss"),
                      }))
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="CheckOut" className="form-label">
                    Check Out
                  </label>
                  <TimePicker
                    format="HH:mm A"
                    className="form-control"
                    id="checkOut"
                    value={moment(editData.checkOut)}
                    onSelect={(time) =>
                      setEditData((prevState) => ({
                        ...prevState,
                        checkOut: moment(editData.checkOut)
                          .set({
                            hour: time.hour(),
                            minute: time.minute(),
                            second: time.second(),
                            millisecond: time.millisecond(),
                          })
                          .format("YYYY-MM-DD HH:mm:ss"),
                      }))
                    }
                  />
                </div>
                <h1 className="modal-title fs-5 mb-md-4 mb-2 d-flex justify-content-between align-items-center mt-3">
                  Break:
                  <div>
                    <button
                      onClick={addBreak}
                      class="add-emp-action d-flex align-items-center justify-content-center ms-2 shadow"
                    >
                      <span class="icon align-text-bottom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </h1>
                <div>
                  {editData.breaks.map((breakData, index) => (
                    <div key={index} className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor={`breakIn-${index}`}
                          className="form-label"
                        >
                          Break Start
                        </label>
                        <TimePicker
                          format="HH:mm A"
                          className="form-control"
                          id={`breakIn-${index}`}
                          value={moment(breakData.start)}
                          onSelect={(time) =>
                            setEditData((prevState) => {
                              const updatedBreaks = [...prevState.breaks];
                              updatedBreaks[index] = {
                                ...updatedBreaks[index],
                                start: moment(breakData.start)
                                  .set({
                                    hour: time.hour(),
                                    minute: time.minute(),
                                    second: time.second(),
                                    millisecond: time.millisecond(),
                                  })
                                  .format("YYYY-MM-DD HH:mm:ss"),
                              };
                              return { ...prevState, breaks: updatedBreaks };
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor={`breakOut-${index}`}
                          className="form-label"
                          style={{ width: "100%", position: "relative" }}
                        >
                          Break End
                          <CloseCircleOutlined
                            onClick={(e) => removeBreak(e, index)}
                            style={{
                              color: "#ed1c1c",
                              position: "absolute",
                              top: "30%",
                              right: "13px",
                            }}
                          />
                        </label>
                        <TimePicker
                          format="HH:mm A"
                          className="form-control"
                          id={`breakOut-${index}`}
                          value={moment(breakData.end)}
                          onSelect={(time) =>
                            setEditData((prevState) => {
                              const updatedBreaks = [...prevState.breaks];
                              updatedBreaks[index] = {
                                ...updatedBreaks[index],
                                end: moment(breakData.end)
                                  .set({
                                    hour: time.hour(),
                                    minute: time.minute(),
                                    second: time.second(),
                                    millisecond: time.millisecond(),
                                  })
                                  .format("YYYY-MM-DD HH:mm:ss"),
                              };
                              return { ...prevState, breaks: updatedBreaks };
                            })
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="col-12 text-center mt-md-5 mt-4 py-2 py-md-0">
                  <button
                    onClick={handleSubmit}
                    className="btn primary-btn-two shadow"
                  >
                    {loading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loading ? "Update" : "Updating..."}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${visible && "show"}`}
        style={{ display: `${visible ? "block" : "none"}` }}
      />
    </>
  );
};

export default EditAttendanceModal;
