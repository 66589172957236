import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth";

function LoginSection() {
  const { login, loginLoading } = useContext(AuthContext);
  const [data, setData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setData({ ...data, [e.target.name]: e.target.checked });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    login(data);
  };
  return (
    <>
      <div className="col-lg-6">
        <div className="form-wrapper position-relative bg-white shadow px-4 px-lg-0 h-100">
          <div className="row g-0 justify-content-center align-items-center h-100">
            <div className="col-xl-9 col-lg-10">
              <form className="sign-in-form">
                <div className="form-title text-center mb-5">
                  <h3 className="display-6 fw-semibold mb-3">
                    <span className="text-muted">
                      <i className="bi bi-person-circle" />
                    </span>{" "}
                    Login
                  </h3>
                  <p className="text-muted">Enter your credentials to login</p>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputUser" className="form-label">
                    Email
                  </label>
                  <input
                    id="text"
                    type="text"
                    className="form-control bg-white"
                    placeholder="Email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    autofocus
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                    type="password"
                    className="form-control bg-white"
                    placeholder="********"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      name="rememberMe"
                      onChange={handleChange}
                      checked={data.rememberMe}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      <small>Remember Me</small>
                    </label>
                  </div>
                  <Link
                    to="/forgot-password"
                    className="text-decoration-none text-end forget-act"
                  >
                    <small>Forgot Password?</small>
                  </Link>
                </div>
                <button
                  onClick={handleSubmit}
                  disabled={loginLoading}
                  className="btn primary-btn-two shadow w-100"
                >
                   {loginLoading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loginLoading ? "Log In" : "Logging In..."}
                </button>
              </form>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.form-wrapper*/}
      </div>
      {/*.col-grid*/}
    </>
  );
}

export default LoginSection;
