import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import jwtDecode from "jwt-decode";
import setAuthToken from "../axios/axiosSetToken";
import { GET_ME_URL, LOGIN_URL, USER_MY_INFO_URL } from "../endPoints";
const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [user, setUser] = useState({});
  const [info, setInfo] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getUser = (token) => {
    setLoading(true);
    axios
      .get(GET_ME_URL)
      .then((res) => {
        setUser(res.data.data.user);
        setIsAuthenticated(res.data.data.isAuthenticated);
        setLoading(false);
        setAuthToken(token);
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          logout();
          return;
        }
      })
      .catch((err) => {
        logout();
        setLoading(false);
      });
  };

  const login = (data) => {
    setLoginLoading(true);
    axios
      .post(LOGIN_URL, data)
      .then((res) => {
        setUser(res.data.data.user);
        setIsAuthenticated(res.data.data.isAuthenticated);
        setAuthToken(res.data.data.token);
        localStorage.setItem("token", res.data.data.token);
        if (res.data.data.user?.role === "employee") {
          navigate("/attendance");
        }
        if (
          res.data.data.user?.role === "company" ||
          res.data.data.user?.role === "manager"
        ) {
          navigate("/dashboard");
        }
        if (res.data.data.user?.role === "superAdmin") {
          navigate("/admin/dashboard");
        }
        message.success("Login successful. Welcome back!");
        setLoginLoading(false);
      })
      .catch((err) => {
        setLoginLoading(false);
        message.error("Invalid login credentials.");
      });
  };

  const getMyInfo = () => {
    axios.get(USER_MY_INFO_URL).then((res) => {
      setInfo(res.data);
      setIsAuthenticated(true);
    });
  };

  const logout = () => {
    setUser({});
    setIsAuthenticated(false);
    setAuthToken();
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <AuthContext.Provider
      value={{
        permissionOnlyAdmin: user?.role === "company",
        user,
        getMyInfo,
        info,
        isAuthenticated,
        loading,
        login,
        logout,
        getUser,
        loginLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContextProvider, AuthContext };
