import React from "react";
import { Link } from "react-router-dom";

function LogoSection() {
  return (
    <>
      <div className="col-lg-6">
        <div className="content-wrapper px-4 px-lg-0 h-100 text-center">
          <div className="row justify-content-center h-100">
            <div className="col-xl-9 col-lg-10 align-self-center">
              <div className="logo-wrapper mb-4">
                <Link to="/">
                  <img
                    src="assets/images/logo-white.png"
                    className="img-fluid"
                    alt="logo"
                  />
                </Link>
              </div>
              <h3 className="display-6 fw-semibold text-white">
                Attendance Management System
              </h3>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.content-wrapper*/}
      </div>
      {/*.col-grid*/}
    </>
  );
}

export default LogoSection;
