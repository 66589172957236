import React, { useEffect, useState } from "react";
import UploadWidget from "../adminProfile/uploadWidget";
import axios from "axios";
import { ADMIN_COMPANY_EDIT_URL, POST_USER_IMAGE_URL } from "../../endPoints";
import { message } from "antd";

function EditCompanyModal({ visible, editData, setVisible, getData }) {
  const [loading, setLoading] = useState(false);
  const [employeeImage, setEmployeeImage] = useState(
    editData?.profile?.imageUrl
  );
  const [employee, setEmployee] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    designation: "",
    salary: "",
    status: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleImageUpload = (result) => {
    const imageUrl = result.info.secure_url;
    axios
      .post(`${POST_USER_IMAGE_URL}/${editData._id}`, { imageUrl })
      .then(() => {
        setEmployeeImage(imageUrl);
      });
  };

  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      employee.confirmPassword?.length > 0 &&
      employee.newPassword?.length > 0
    ) {
      if (employee.confirmPassword !== employee.newPassword) {
        message.warning(
          "Confirm password must match new password. Please try again."
        );
        return;
      }

      setLoading(true);
      axios
        .put(`${ADMIN_COMPANY_EDIT_URL}/${editData.userId._id}`, {
          ...employee,
          imageUrl: employeeImage,
        })
        .then(() => {
          getData();
          setTimeout(() => {
            setEmployeeImage("");
            setVisible(false);
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      return;
    }
    delete employee.newPassword;
    delete employee.confirmPassword;
    setLoading(true);
    axios
      .put(`${ADMIN_COMPANY_EDIT_URL}/${editData.userId._id}`, {
        ...employee,
        imageUrl: employeeImage,
      })
      .then(() => {
        getData();
        setTimeout(() => {
          setEmployeeImage("");
          setVisible(false);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setEmployee({
      name: editData?.name,
      email: editData?.email,
      password: editData?.password,
      phoneNumber: editData?.phoneNumber,
      designation: editData?.designation,
      salary: editData?.salary,
      status: editData?.userId?.status,
    });
    setEmployeeImage("");
  }, [editData]);
  return (
    <>
      <div
        className={`modal fade ${visible && "show"}`}
        style={{ display: `${visible ? "block" : "none"}` }}
        id="editEmployeeModal"
        // tabIndex={-1}
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content px-4 py-md-5 py-4">
            <div className="modal-header text-center d-block py-0 border-0 pb-3">
              <h1
                className="modal-title fs-5 mb-md-4 mb-2"
                id="exampleModalLabel"
              >
                Edit Company Infomation
              </h1>
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0"
                onClick={() => {
                  setVisible(false);
                }}
                // data-bs-dismiss="modal"
                // aria-label="Close"
              />
            </div>
            <div className="modal-body p-0">
              <div className="col-md-12 mb-md-4 mb-2">
                <UploadWidget onUpload={handleImageUpload} />
              </div>
              <form className="row g-3 user-edit-form">
                <div className="col-md-6">
                  <label htmlFor="Name" className="form-label">
                    Name
                  </label>
                  <input
                    value={employee.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="Name"
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="PhoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <input
                    value={employee.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="PhoneNumber"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Email" className="form-label">
                    Email
                  </label>
                  <input
                    value={employee.email}
                    name="email"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="Email"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Stauts" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    value={employee.status}
                    name="status"
                    onChange={handleChange}
                    id="Status"
                  >
                    <option value={"active"}>Active</option>
                    <option value={"inactive"}>Inactive</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="NewPassword" className="form-label">
                    New Password
                  </label>
                  <input
                    value={employee.newPassword}
                    name="newPassword"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="NewPassword"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="ConfirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    value={employee.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="ConfirmPassword"
                  />
                </div>

                <div className="col-12 text-center mt-md-5 mt-4 py-2 py-md-0">
                  <button
                    disabled={loading}
                    onClick={handleSubmit}
                    type="submit"
                    className="btn primary-btn-two shadow"
                  >
                    {loading ? "Updating Profile..." : "Update Profile"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCompanyModal;
