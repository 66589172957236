import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../endPoints";

function ForgotPassSec() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email)
      return message.error(
        "Email required to reset password. Please enter email."
      );
    setLoading(true);
    axios
      .post(FORGET_PASSWORD_URL, { email })
      .then((res) => {
        setLoading(false);
        message.success(
          "Password reset email sent. Check your inbox and spam folder!"
        );
      })
      .catch((err) => {
        setLoading(false);
        message.warning(err.response.data.message);
      });
  };
  return (
    <>
      <div className="col-lg-6">
        <div className="form-wrapper position-relative bg-white shadow px-4 px-lg-0 h-100">
          <div className="row g-0 justify-content-center align-items-center h-100">
            <div className="col-xl-9 col-lg-10">
              <form className="sign-in-form">
                <div className="form-title text-center mb-5">
                  <h3 className="display-6 fw-semibold mb-3">
                    Forgot Password
                  </h3>
                  <p className="text-muted">
                    Back to Login?{" "}
                    <Link to="/" className="bk-login">
                      Login
                    </Link>
                  </p>
                </div>
                <div className="mb-4">
                  <label htmlFor="exampleInputEmail" className="form-label">
                    Email
                  </label>
                  <input
                    id="text"
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control bg-white"
                    placeholder="Enter your email address"
                    autofocus
                  />
                </div>
                <button
                  disabled={loading}
                  onClick={handleSubmit}
                  className="btn primary-btn-two shadow w-100"
                >
                  {loading && (
                    <span className="spinner-grow spinner-grow-sm me-2"></span>
                  )}
                  {!loading ? "Reset Password" : "Sending Email..."}
                </button>
                <p className="mt-4 text-center text-muted">
                  Enter your registered email address to reset your password.
                </p>
              </form>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.form-wrapper*/}
      </div>
      {/*.col-grid*/}
    </>
  );
}

export default ForgotPassSec;
