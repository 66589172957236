import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../components/mainLayout/adminLayout";
import AdminNavigation from "../../components/mainLayout/navigation/adminNavigation";
import AdminModal from "../../components/adminProfile/adminModal";
import AdminImage from "../../components/adminProfile/adminImage";
import AdminInfo from "../../components/adminProfile/adminInfo";

import { AuthContext } from "../../context/auth";

function AdminProfile() {
  const { info, getMyInfo } = useContext(AuthContext);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <title>AAMAX - Dashboard</title>
      {/*====================== Start Dashboard ======================*/}
      <section className="ams-dashboard admin-dashboard">
        <AdminLayout />
        {/*.navbar*/}
        <div className="container-fluid p-0">
          <AdminNavigation />
          {/*.sidebar*/}
          <main className="page-content-wrapper">
            <div className="page-content-inner">
              <div className="page-header text-center text-md-start py-3 mb-lg-2">
                <h4 className="my-2">Welcome {info?.name}!</h4>
              </div>
              {/*.page-header*/}
              <div className="row">
                <div className="col-12">
                  <div className="card-wrapper admin-details-wrapper p-md-4 p-3">
                    <div className="row">
                      <AdminImage />
                      {/*.card-header*/}
                      <div className="card-body col-md-8 position-relative ps-md-5 py-2">
                        <a
                          // href="#"
                          className="edit-icon position-absolute top-0 end-0"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModal"
                          onClick={() => setVisible(true)}
                        >
                          <i className="bi bi-pencil-fill" />
                        </a>
                        <AdminInfo />
                        {/* Modal */}
                        <AdminModal visible={visible} setVisible={setVisible} />
                      </div>
                      {/*.card-body*/}
                    </div>
                  </div>
                  {/*.card-wrapper*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.page-content-inner*/}
          </main>
          {/*.page-content-wrapper*/}
        </div>
        {/*.container-fluid*/}
      </section>
      {/*====================== End Dashboard ======================*/}
    </div>
  );
}

export default AdminProfile;
