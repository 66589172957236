import React, { useContext } from "react";
import { DateContext } from "../../context/dateContext";
import { getHours } from "../../util";
import moment from "moment";

function RecordWorkingDetail({ attendances, attendanceRecord }) {
  const { startingDate, endingDate } = useContext(DateContext);

  // const filteredAttendances = attendances?.filter((att) => {
  //   const date = moment(att?.date);
  //   return date >= startingDate && date <= endingDate && att.workedHours;
  // });

  const start = moment(startingDate);
  const end = moment(endingDate);
  const totalDays = end.diff(start, "days") + 1 || "0";

  const workedDays = attendanceRecord?.length;

  const workedHours = attendanceRecord
    ?.map((a) => {
      const workDuration = moment.duration(a?.workedHours);
      return workDuration;
    })
    .reduce((a, b) => a + b, 0);

  return (
    <>
      <div className="card-footer p-3 p-md-4">
        <div className="row gx-xxl-5">
          <div className="col-sm-4 mb-3">
            <div className="text-center shadow-sm px-3 py-4 rounded bg-light block-hover">
              <h4>{totalDays}</h4>
              <p className="text-muted m-0">Total Days</p>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="text-center shadow-sm px-3 py-4 rounded bg-light block-hover">
              <h4>{workedDays}</h4>
              <p className="text-muted m-0">Days Worked</p>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="text-center shadow-sm px-3 py-4 rounded bg-light block-hover">
              <h4>{getHours(workedHours)}</h4>
              <p className="text-muted m-0">Worked Hours</p>
            </div>
          </div>
        </div>
      </div>
      {/*.card-footer*/}
    </>
  );
}

export default RecordWorkingDetail;
