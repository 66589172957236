// import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { AuthContext } from "../../context/auth";

function ProtectedRoute(props) {
  // const { isAuthenticated } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  // verfiy token from api here

  return !token ? <Navigate to={"/"} /> : <Outlet />;
}

export default ProtectedRoute;
