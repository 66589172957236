import React, { useContext, useEffect, useState } from "react";
import Pagination from "./pagination";
import axios from "axios";
import { ADMIN_EMPLOYEES_TODAY_ATT_URL } from "../../endPoints";
import moment from "moment";
import { getHours } from "../../util";
import { AttContext } from "../../context/attContext";
import { DELETE_ATTENDANCE_URL } from "../../endPoints";

import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import EditAttendanceModal from "../attendance/editAttendanceModal";
import DashboardSkeleton from "./dashboardSkeleton";
import { AuthContext } from "../../context/auth";

function TodayAttendance() {
  const { setEmployeeId } = useContext(AttContext);
  const { permissionOnlyAdmin } = useContext(AuthContext);

  const confirm = (id) => {
    axios
      .delete(`${DELETE_ATTENDANCE_URL}/${id}`)
      .then(() => {
        getData();
        message.success("Attendance record deleted successfully.");
      })
      .catch((err) => {
        message.warning(
          "Failed to delete attendance record. Please try again."
        );
        console.error(err);
      });
  };

  const [data, setData] = useState([]);
  const [searchEmpl, setSearchEmpl] = useState("");
  const [editData, setEditData] = useState({});
  const [visible, setVisible] = useState(false);
  const [attId, setAttId] = useState(null);
  const [loading, setLoading] = useState(true);
  const page_size = 10;
  const [nextPageCount, setNextPageCount] = useState(page_size);
  const [lastPageItemsCount, setLastPageItemsCount] = useState(page_size);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const handleSetEditData = (d) => {
    const obj = {
      checkIn: d?.attObject?.checkIn || "",
      checkOut: d?.attObject?.checkOut || "",
      breaks: d?.attObject?.breaks || [],
    };
    setEditData(obj);
    setAttId(d?.attObject?._id);
  };
  const getData = () => {
    setLoading(true);
    axios
      .get(
        ADMIN_EMPLOYEES_TODAY_ATT_URL +
          `?currentPage=${paginationData.currentPage}&pageSize=${page_size}`
      )
      .then((res) => {
        const sortedData = res.data.data.sort((a, b) => {
          if (
            a?.attObject &&
            moment(a?.attObject?.createdAt).isSame(new Date(), "day")
          ) {
            return moment(a?.attObject?.createdAt).diff(
              moment(b?.attObject?.createdAt)
            );
          }
          return false;
        });
        setData(sortedData);
        setLastPageItemsCount(res.data.meta.lastPageItemsCount);
        setNextPageCount(res.data.meta.nextPageCount);
        setPaginationData({
          currentPage: res.data.meta.currentPage,
          totalPages: res.data.meta.totalPages,
        });
        setLoading(false);
      });
  };

  const leaveCondition = (checkIn) => {
    return (
      !checkIn &&
      moment().format("HH:mm") >= "12:00" &&
      moment().format("HH:mm") <= "23:59"
    );
  };

  const filteredEmployees = data.filter((empl) =>
    empl.name.toLowerCase().includes(searchEmpl.toLowerCase())
  );

  const handleSetEmployeeId = (id) => {
    setEmployeeId(id);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData.currentPage]);
  return (
    <>
      <main className="page-content-wrapper">
        <div className="page-content-inner">
          <div className="page-header text-center text-md-start py-3 mb-lg-2">
            <h4 className="my-2">Today's Attendance</h4>
          </div>
          {/*.page-header*/}
          <div className="row">
            <div className="col-12">
              <div className="card-wrapper">
                <div className="card-header m-3 m-md-4 mb-md-0 mb-0 bg-light">
                  <div className="row">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                      <div className="search-wrapper d-flex align-items-center">
                        <label
                          className="h6 fw-semibold me-2 mb-0"
                          htmlFor="searh-input"
                        >
                          Today
                        </label>
                        <form
                          className="position-relative flex-fill"
                          action="#"
                        >
                          <input
                            className="form-control w-100 rounded-pill border"
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            value={searchEmpl}
                            onChange={(e) => setSearchEmpl(e.target.value)}
                          />
                          <span className="search-box-icon position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={14}
                              height={14}
                              fill="currentColor"
                              className="bi bi-search"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </span>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/*.card-header*/}
                <div className="card-body p-3 p-md-4 pt-md-0 pt-0">
                  <div className="table-responsive">
                    <table className="table table-hover emp-attend-list">
                      <thead>
                        <tr>
                          <th scope="col" className="img-col">
                            Photo
                          </th>
                          <th scope="col">Name</th>
                          <th scope="col">Check In</th>
                          <th scope="col">Break</th>
                          <th scope="col">Check Out</th>
                          <th scope="col">Time Worked</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <DashboardSkeleton
                            withImg
                            length={
                              nextPageCount === 0 ? page_size : nextPageCount
                            }
                          />
                        ) : (
                          <>
                            {filteredEmployees?.map((d) => {
                              if (
                                d?.attObject &&
                                moment(d?.attObject?.createdAt).isSame(
                                  new Date(),
                                  "day"
                                )
                              ) {
                                return (
                                  <tr key={d?.attObject._id}>
                                    <td>
                                      <img
                                        src={d?.userId.profile?.imageUrl}
                                        className="table-img fit-user-img"
                                      />
                                    </td>
                                    <td>{d.name}</td>
                                    <td
                                      className={
                                        moment
                                          .utc(d?.attObject?.checkIn)
                                          .local()
                                          .format("HH:mm") >= "10:00"
                                          ? "red-col"
                                          : "green-col"
                                      }
                                    >
                                      {d?.attObject?.checkIn
                                        ? moment
                                            .utc(d?.attObject?.checkIn)
                                            .local()
                                            .format("h:mm A")
                                        : "--"}
                                    </td>
                                    <td>
                                      {d?.attObject?.breakTime
                                        ? getHours(d?.attObject?.breakTime)
                                        : "--"}
                                    </td>
                                    <td className="green-col">
                                      {d?.attObject?.checkOut ? (
                                        moment(d?.attObject?.checkOut).format(
                                          "LT"
                                        )
                                      ) : (
                                        <span style={{ color: "#212529" }}>
                                          --
                                        </span>
                                      )}
                                    </td>
                                    <td
                                      className={
                                        moment(
                                          d?.attObject?.workedHours,
                                          "HH:mm"
                                        ).format("HH:mm") < "08:00"
                                          ? "red-col"
                                          : "green-col"
                                      }
                                    >
                                      {d?.attObject?.workedHours ? (
                                        getHours(d?.attObject?.workedHours)
                                      ) : (
                                        <span style={{ color: "#212529" }}>
                                          --
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <span className="d-inline-block badge green-col">
                                        Present
                                      </span>
                                    </td>
                                    <td>
                                      {permissionOnlyAdmin && (
                                        <>
                                          <button
                                            className="tb-action edit"
                                            onClick={() => {
                                              setVisible(true);
                                              handleSetEditData(d);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={18}
                                              height={18}
                                              fill="currentColor"
                                              className="bi bi-pencil-square"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                              <path
                                                fillRule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                              />
                                            </svg>
                                          </button>
                                          <Popconfirm
                                            title="Confirm Delete"
                                            description="Are you sure you want to delete this attendance record?"
                                            onConfirm={() =>
                                              confirm(d?.attObject._id)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <button className="tb-action delete">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={18}
                                                height={18}
                                                fill="currentColor"
                                                className="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                              </svg>
                                            </button>
                                          </Popconfirm>
                                        </>
                                      )}
                                      <Link
                                        to="/employee-attendance"
                                        className="d-inline-flex v-att-action"
                                        onClick={() =>
                                          handleSetEmployeeId(d._id)
                                        }
                                      >
                                        <span className="align-text-bottom me-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-box-arrow-up-right"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                            />
                                          </svg>
                                        </span>{" "}
                                        Attendance
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr key={d._id}>
                                    <td>
                                      <img
                                        src={d?.userId.profile?.imageUrl}
                                        className="table-img fit-user-img"
                                      />
                                    </td>
                                    <td>{d.name}</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>
                                      {leaveCondition() ? (
                                        <span className="d-inline-block badge red-col">
                                          Leave
                                        </span>
                                      ) : (
                                        <td>--</td>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        to="/employee-attendance"
                                        className="d-inline-flex v-att-action"
                                        onClick={() =>
                                          handleSetEmployeeId(d._id)
                                        }
                                      >
                                        <span className="align-text-bottom me-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-box-arrow-up-right"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                            />
                                          </svg>
                                        </span>{" "}
                                        Attendance
                                      </Link>
                                    </td>{" "}
                                  </tr>
                                );
                              }
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/*.table-responsive*/}
                  {/* Edit Attendance Modal */}
                  <EditAttendanceModal
                    visible={visible}
                    setVisible={setVisible}
                    data={editData}
                    attId={attId}
                    getData={getData}
                  />
                </div>
                {/*.card-body*/}
                <Pagination
                  lastPageItemsCount={lastPageItemsCount}
                  pageSize={page_size}
                  paginationData={paginationData}
                  setPaginationData={setPaginationData}
                  setNextPageCount={setNextPageCount}
                />
                {/*.card-footer*/}
              </div>
              {/*.card-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.page-content-inner*/}
      </main>
    </>
  );
}

export default TodayAttendance;
