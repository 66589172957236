import React, { useContext, useState } from "react";
import axios from "axios";
import { ADD_USER_URL } from "../../endPoints";
import { message } from "antd";
import UploadWidget from "../adminProfile/uploadWidget";
import { AuthContext } from "../../context/auth";

function AddEmployeeModal({ visible, setVisible, getData }) {
  const { permissionOnlyAdmin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const initialState = {
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    designation: "",
    salary: "",
    status: "active",
    role: "employee",
  };
  const [employee, setEmployee] = useState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = permissionOnlyAdmin
      ? { ...employee, imageUrl }
      : {
          name: employee.name,
          email: employee.email,
          password: employee.password,
          phoneNumber: employee.phoneNumber,
          designation: employee.designation,
          salary: employee.salary,
          status: employee.status,
          imageUrl,
        };
    axios
      .post(ADD_USER_URL, data)
      .then((res) => {
        getData();
        message.success("New employee profile created successfully.");
        setVisible(false);
        setLoading(false);
        setEmployee(initialState);
        setImageUrl("");
      })
      .catch((err) => {
        setLoading(false);
        message.warning(
          "Failed to create new employee profile. Please try again."
        );
      });
  };

  const handleImageUpload = (result) => {
    const url = result.info.secure_url;
    setImageUrl(url);
  };

  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className={`modal fade ${visible && "show"}`}
        style={{ display: `${visible ? "block" : "none"}` }}
        id="addEmployeeModal"
        tabIndex={-1}
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content px-4 py-md-5 py-4">
            <div className="modal-header text-center d-block py-0 border-0 pb-3">
              <h1
                className="modal-title fs-5 mb-md-4 mb-2"
                id="exampleModalLabel"
              >
                Add New Employee
              </h1>
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => {
                  setVisible(false);
                }}
              />
            </div>
            <div className="modal-body p-0">
              <div className="col-md-12 mb-md-4 mb-2">
                <UploadWidget onUpload={handleImageUpload} />
              </div>
              <form className="row g-3 user-edit-form">
                <div className="col-md-12"></div>
                <div className="col-md-6">
                  <label htmlFor="Name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Name"
                    name="name"
                    value={employee.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Departmentt" className="form-label">
                    Designation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Department"
                    name="designation"
                    value={employee.designation}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Mobile" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    name="phoneNumber"
                    value={employee.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Email"
                    name="email"
                    value={employee.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Salary" className="form-label">
                    Salary
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Salary"
                    name="salary"
                    value={employee.salary}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Salary" className="form-label">
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Password"
                    name="password"
                    value={employee.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="stauts" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    name="status"
                    value={employee.status}
                    onChange={handleChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>

                  {/* <option selected>Active</option>
                    <option value={1}>Inactive</option> */}
                </div>

                {permissionOnlyAdmin && (
                  <div className="col-md-6">
                    <label htmlFor="role" className="form-label">
                      Role
                    </label>
                    <select
                      className="form-select form-control"
                      aria-label="Default select example"
                      name="role"
                      value={employee.role}
                      onChange={handleChange}
                    >
                      <option value="employee">Employee</option>
                      <option value="manager">Manager</option>
                    </select>
                  </div>
                )}
                <div className="col-12 text-center mt-md-5 mt-4 py-2 py-md-0">
                  <button
                    disabled={loading}
                    onClick={handleSubmit}
                    className="btn primary-btn-two shadow"
                  >
                    {loading ? "Creating Profile..." : "Add Employee"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEmployeeModal;
