import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/auth";
import axios from "axios";
import { EDIT_USER_URL } from "../../endPoints";
import UploadWidget from "./uploadWidget";

import { ImageContext } from "../../context/imageContext";
import { POST_IMAGE_URL } from "../../endPoints";
import { message } from "antd";

function EditProfileModal({ visible, setVisible }) {
  const { getImage } = useContext(ImageContext);

  const { info, getUser, getMyInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    designation: "",
    phoneNumber: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleImageUpload = (result) => {
    const imageUrl = result.info.secure_url;
    axios.post(POST_IMAGE_URL, { imageUrl }).then(() => {
      getImage();
    });
  };
  const handleChange = ({ target }) => {
    setData((prevState) => ({ ...prevState, [target.name]: target.value }));
  };
  const handleSubmit = (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();
    if (data.confirmPassword?.length > 0 && data.newPassword?.length > 0) {
      if (data.confirmPassword !== data.newPassword) {
        message.warning(
          "Confirm password must match new password. Please try again."
        );
        return;
      }

      setLoading(true);
      axios
        .put(EDIT_USER_URL, data)
        .then(() => {
          getUser(token);
          getMyInfo();
          setTimeout(() => {
            setVisible(false);
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      return;
    }
    delete data.newPassword;
    delete data.confirmPassword;
    setLoading(true);
    axios
      .put(EDIT_USER_URL, data)
      .then(() => {
        getUser(token);
        getMyInfo();
        setTimeout(() => {
          setVisible(false);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    setData({
      name: info?.name || "",
      email: info?.email || "",
      designation: info?.designation || "",
      phoneNumber: info?.phoneNumber || "",
    });
  }, [info]);

  return (
    <>
      <div
        className={`modal fade ${visible && "show"}`}
        id="exampleModal"
        tabIndex={-1}
        style={{ display: `${visible ? "block" : "none"}` }}
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content px-4 py-md-5 py-4">
            <div className="modal-header text-center d-block py-0 border-0 pb-3">
              <h1
                className="modal-title fs-5 mb-md-4 mb-2"
                id="exampleModalLabel"
              >
                Personal Information
              </h1>
              <button
                type="button"
                onClick={() => {
                  setVisible(false);
                }}
                className="btn-close position-absolute top-0 end-0"
                // data-bs-dismiss="modal"
                // aria-label="Close"
              />
            </div>
            <div className="modal-body p-0">
              <div className="col-md-12 mb-md-4 mb-2">
                <UploadWidget onUpload={handleImageUpload} />
              </div>
              <form className="user-edit-form">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="inputName" className="form-label">
                      Name
                    </label>
                    <input
                      name="name"
                      onChange={handleChange}
                      value={data?.name}
                      type="text"
                      className="form-control"
                      id="inputName"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4" className="form-label">
                      Email
                    </label>
                    <input
                      name="email"
                      onChange={handleChange}
                      value={data?.email}
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                  {/* <div className="col-md-6">
                  <label htmlFor="inputDesignation" className="form-label">
                    Designation
                  </label>
                  <input
                    name="designation"
                    onChange={handleChange}
                    value={data?.designation}
                    type="text"
                    className="form-control"
                    id="inputDesignation"
                  />
                </div> */}

                  <div className="col-md-6">
                    <label htmlFor="inputPhone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      name="phoneNumber"
                      onChange={handleChange}
                      value={data?.phoneNumber}
                      type="text"
                      className="form-control"
                      id="inputPhone"
                    />
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <label htmlFor="inputCity" className="form-label">
                    City
                  </label>
                  <input type="text" className="form-control" id="inputCity" />
                </div> */}
                <div className="row g-3 mt-1">
                  <div className="col-md-6">
                    <label htmlFor="inputPassword4" className="form-label">
                      New Password
                    </label>
                    <input
                      id="newpassword"
                      name="newPassword"
                      type="password"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputPassword4" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      id="confirmpassword"
                      name="confirmPassword"
                      type="password"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 text-center mt-md-5 mt-4 py-2 py-md-0">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn primary-btn-two shadow"
                    // data-bs-dismiss="modal"
                  >
                    {loading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loading ? "Update Profile" : "Updating..."}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${visible && "show"}`}
        style={{ display: `${visible ? "block" : "none"}` }}
      />
    </>
  );
}

export default EditProfileModal;
