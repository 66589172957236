import React from "react";
import AdminLayout from "../../components/mainLayout/adminLayout";
import AdminNavigation from "../../components/mainLayout/navigation/adminNavigation";
import EmployeeInfo from "../../components/employeeAtt/employeeInfo";
import AttendanceTable from "../../components/employeeAtt/attendanceTable";
import EmployeeAttDetail from "../../components/employeeAtt/employeeAttDetail";
import EmployeeAttModal from "../../components/employeeAtt/employeeAttModal";
import { Helmet } from "react-helmet";

function EmployeeAttendace() {
  return (
    <div>
      <Helmet>
        <title>Employee Attendance Overview - AAMAX</title>
      </Helmet>
      {/*====================== Start Dashboard ======================*/}
      <section className="ams-dashboard admin-dashboard">
        <AdminLayout />
        {/*.navbar*/}
        <div className="container-fluid p-0">
          <AdminNavigation />

          {/*.sidebar*/}
          <main className="page-content-wrapper">
            <div className="page-content-inner">
              <div className="page-header text-center text-md-start py-3 mb-lg-2">
                <h4 className="my-2">Employee Attendance Overview</h4>
              </div>
              {/*.page-header*/}
              <div className="row">
                <EmployeeInfo />
                <div className="col-12">
                  <div className="card-wrapper">
                    <AttendanceTable />
                    {/*.card-body*/}
                    <EmployeeAttDetail />
                    {/*.card-footer*/}
                  </div>
                  {/*.card-wrapper*/}
                  {/* Edit Attendance Modal */}
                  <EmployeeAttModal />
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.page-content-inner*/}
          </main>
          {/*.page-content-wrapper*/}
        </div>
        {/*.container-fluid*/}
      </section>
      {/*====================== End Dashboard ======================*/}
    </div>
  );
}

export default EmployeeAttendace;
