import React from "react";

function EmployeeAttModal() {
  return (
    <>
      <div
        className="modal fade"
        id="editAttendModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content px-4 py-md-5 py-4">
            <div className="modal-header text-center d-block py-0 border-0 pb-3">
              <h1
                className="modal-title fs-5 mb-md-4 mb-2"
                id="exampleModalLabel"
              >
                Attendace Information
              </h1>
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-0">
              <form className="row g-3 user-edit-form">
                <div className="col-md-6">
                  <label htmlFor="CheckIn" className="form-label">
                    Check In
                  </label>
                  <input type="text" className="form-control" id="CheckIn" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="CheckOut" className="form-label">
                    Check Out
                  </label>
                  <input type="text" className="form-control" id="CheckOut" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="BreakIn" className="form-label">
                    Break In
                  </label>
                  <input type="text" className="form-control" id="BreakIn" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="BreakOut" className="form-label">
                    Break Out
                  </label>
                  <input type="text" className="form-control" id="BreakOut" />
                </div>
                <div className="col-12 text-center mt-md-5 mt-4 py-2 py-md-0">
                  <button type="submit" className="btn primary-btn-two shadow">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeAttModal;
