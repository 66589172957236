import React, { useContext } from "react";

import { AuthContext } from "../../context/auth";
import { ImageContext } from "../../context/imageContext";

function AdminImage() {
  const { profileImage } = useContext(ImageContext);
  const { user } = useContext(AuthContext);

  return (
    <>
      <div className="card-header col-md-4 py-2">
        <div className="d-flex flex-column flex-xl-row align-items-center">
          <div className="user-img-edit me-sm-3 mb-3 mb-sm-0">
            <div className="user-img-wrapper mb-2">
              <img
                src={profileImage || "assets/images/logo.png"}
                className="rounded-pill user-img fit-user-img"
                width={110}
                height={110}
                alt="user"
              />
            </div>
          </div>
          <div className="admin-info-text text-center text-xl-start">
            <h5 className="mb-1">{user?.name}</h5>
            <p className="text-muted m-0">
              <small>{user?.designation}</small>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminImage;
