import React from "react";

function SearchNAddEmployee({ setVisible, searchEmpl, setSearchEmpl, noAdd }) {
  return (
    <>
      <div className="search-wrapper flex-fill" style={{ maxWidth: "300px" }}>
        <form className="position-relative" action="#">
          <input
            className="form-control w-100 rounded-pill border"
            type="text"
            placeholder="Search by Name or Email"
            aria-label="Search"
            value={searchEmpl}
            onChange={(e) => setSearchEmpl(e.target.value)}
          />
          <span className="search-box-icon position-absolute">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={14}
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </span>
        </form>
      </div>
      {!noAdd && (
        <button
          className="add-emp-action d-flex align-items-center justify-content-center ms-2 shadow"
          onClick={() => setVisible(true)}
          // data-bs-toggle="modal"
          // data-bs-target="#addEmployeeModal"
        >
          <span className="icon align-text-bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={32}
              height={32}
              fill="currentColor"
              className="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </span>
        </button>
      )}
    </>
  );
}

export default SearchNAddEmployee;
