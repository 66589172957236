import { useEffect, useRef } from "react";

const UploadWidget = ({ onUpload }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dmkou0hmb",
        uploadPreset: "amsimages",
      },
      function (error, result) {
        if (!error && result && result.event === "success") {
          onUpload(result);
        }
      }
    );
  }, [onUpload]);

  return (
    <>
      <button
        className="btn secondary-btn shadow-sm "
        onClick={() => widgetRef.current.open()}
      >
        <span className="icon align-text-bottom me-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="currentColor"
            className="bi bi-upload"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
          </svg>
        </span>
        Upload Image
      </button>
    </>
  );
};

export default UploadWidget;
