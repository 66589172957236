import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UPDATE_PASSWORD_URL } from "../../endPoints";

function ResetPassword({ token }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (password.confirmPassword !== password.newPassword) {
      message.warning(
        "Confirm password must match new password. Please try again."
      );
      setLoading(false);
    }
    axios
      .post(UPDATE_PASSWORD_URL, {
        ...password,
        token,
      })
      .then((res) => {
        setLoading(false);
        message.success("Password Updated");
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="col-lg-6">
        <div className="form-wrapper position-relative bg-white shadow px-4 px-lg-0 h-100">
          <div className="row g-0 justify-content-center align-items-center h-100">
            <div className="col-xl-9 col-lg-10">
              <form className="sign-in-form">
                <div className="form-title text-center mb-sm-5 mb-4">
                  <h3 className="display-6 fw-semibold mb-sm-3 mb-2">
                    Reset Password
                  </h3>
                  <p className="text-muted">Enter your new password below:</p>
                </div>
                <div className="mb-4">
                  <label htmlFor="newpassword" className="form-label">
                    Password
                  </label>
                  <input
                    id="newpassword"
                    name="newPassword"
                    type="password"
                    onChange={handleChange}
                    className="form-control bg-white"
                    placeholder="*********"
                    autofocus
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="confirmpassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    id="confirmpassword"
                    name="confirmPassword"
                    type="password"
                    onChange={handleChange}
                    className="form-control bg-white"
                    placeholder="*********"
                    autofocus
                  />
                </div>
                <button
                  type="submit"
                  className="btn primary-btn-two shadow w-100"
                  onClick={submit}
                >
                  Update Password
                </button>
              </form>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.form-wrapper*/}
      </div>
      {/*.col-grid*/}
    </>
  );
}

export default ResetPassword;
