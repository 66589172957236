import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { GET_EMPLOYEE_URL, USER_ATTENDANCE_URL } from "../endPoints";
import axios from "axios";

const AttContext = createContext();

const EmplAttProvider = ({ children }) => {
  const [userAttendance, setUserAttendance] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [userData, setUserData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (employeeId) {
      setLoading(true);
      axios
        .all([
          axios.get(`${USER_ATTENDANCE_URL}/${employeeId}`),
          axios.get(`${GET_EMPLOYEE_URL}/${employeeId}`),
        ])
        .then(
          axios.spread((attendanceRes, userRes) => {
            setUserAttendance(attendanceRes.data.data);
            setUserData(userRes.data.data);
            setLoading(false);
          })
        );
    }
  }, [employeeId, deleteStatus, editStatus]);

  const mergedData = [...userAttendance]
    .filter(
      (data) =>
        moment(data.date).month() === moment(selectedMonth).month() &&
        moment(data.date).year() === moment(selectedMonth).year()
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <AttContext.Provider
      value={{
        setEmployeeId,
        mergedData,
        userData,
        selectedMonth,
        setSelectedMonth,
        setDeleteStatus,
        deleteStatus,
        setEditStatus,
        editStatus,
        loading,
      }}
    >
      {children}
    </AttContext.Provider>
  );
};

export { EmplAttProvider, AttContext };
