import React, { useContext } from "react";
import { getHours } from "../../util";
import moment from "moment";
import { AttContext } from "../../context/attContext";

function EmployeeAttDetail() {
  const { mergedData, selectedMonth } = useContext(AttContext);

  const filteredAttendance = mergedData?.filter(
    (att) =>
      moment(att.date).month() === moment(selectedMonth).month() &&
      moment(att.date).year() === moment(selectedMonth).year() &&
      att.workedHours
  );

  const totalDays = moment(selectedMonth).daysInMonth();

  const workedDays = filteredAttendance?.length;

  const workedHours = filteredAttendance
    ?.map((a) => {
      const workDuration = moment.duration(a?.workedHours);
      return workDuration;
    })
    .reduce((a, b) => a + b, 0);

  return (
    <>
      <div className="card-footer p-3 p-md-4">
        <div className="row gx-xxl-5">
          <div className="col-sm-4 mb-3">
            <div className="text-center shadow-sm px-3 py-4 rounded bg-light block-hover">
              <h4>{totalDays}</h4>
              <p className="text-muted m-0">Total Days</p>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="text-center shadow-sm px-3 py-4 rounded bg-light block-hover">
              <h4>{workedDays}</h4>
              <p className="text-muted m-0">Days Worked</p>
            </div>
          </div>
          <div className="col-sm-4 mb-3">
            <div className="text-center shadow-sm px-3 py-4 rounded bg-light block-hover">
              <h4>{getHours(workedHours)}</h4>
              <p className="text-muted m-0">Worked Hours</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeAttDetail;
