import moment from "moment";
import { getHours } from "../../util";
import { AttContext } from "../../context/attContext";
import { useContext, useState } from "react";
import axios from "axios";
import { DELETE_ATTENDANCE_URL } from "../../endPoints";
import { message, Popconfirm } from "antd";
import EditAttendanceModal from "../attendance/editAttendanceModal";
import DashboardSkeleton from "../dashboard/dashboardSkeleton";
import { AuthContext } from "../../context/auth";

function AttendanceTable() {
  const {
    mergedData,
    setDeleteStatus,
    deleteStatus,
    setEditStatus,
    editStatus,
    loading,
  } = useContext(AttContext);
  const { permissionOnlyAdmin } = useContext(AuthContext);
  const [editLeaveData, setEditLeaveData] = useState({});
  const [leaveVisible, setLeaveVisible] = useState(false);
  const [leaveId, setLeaveId] = useState(null);

  const [editData, setEditData] = useState({});
  const [visible, setVisible] = useState(false);
  const [attId, setAttId] = useState(null);

  const handleSetEditData = (d) => {
    const obj = {
      checkIn: d?.checkIn || "",
      checkOut: d?.checkOut || "",
      breaks: d?.breaks || [],
    };
    setEditData(obj);
    setAttId(d?._id);
  };

  const handleLeaveConvertData = (d) => {
    const obj = {
      checkIn: d?.date,
      checkOut: d?.date,
      breaks: [],
    };
    setEditLeaveData(obj);
    setLeaveId(d?._id);
  };

  const confirm = (id) => {
    axios
      .delete(`${DELETE_ATTENDANCE_URL}/${id}`)
      .then(() => {
        message.success("Attendance record deleted successfully.");
        setDeleteStatus(!deleteStatus);
      })

      .catch((err) => {
        message.warning(
          "Failed to delete attendance record. Please try again."
        );
        console.error(err);
      });
  };

  return (
    <>
      <div className="card-body p-3 p-md-4">
        <div className="table-responsive h-limit">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Check In</th>
                <th scope="col">Break</th>
                <th scope="col">Check Out</th>
                <th scope="col">Time Worked</th>
                <th scope="col">Status</th>
                {permissionOnlyAdmin && <th scope="col">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <DashboardSkeleton num={permissionOnlyAdmin ? 7 : 6} />
              ) : (
                mergedData?.map((data) => {
                  if (
                    (data?.checkIn || data?.checkOut) &&
                    data.status !== "leave"
                  ) {
                    return (
                      <tr key={data._id}>
                        <td>{moment(data?.date).format("D-M-yyyy")}</td>
                        <td
                          className={
                            moment.utc(data?.checkIn).local().format("HH:mm") >=
                            "10:00"
                              ? "red-col"
                              : "green-col"
                          }
                        >
                          {data?.checkIn
                            ? moment.utc(data?.checkIn).local().format("h:mm A")
                            : "--"}
                        </td>
                        <td>
                          {data?.breakTime ? getHours(data?.breakTime) : "--"}
                        </td>
                        <td className="green-col">
                          {data?.checkOut ? (
                            moment(data?.checkOut).format("LT")
                          ) : (
                            <span style={{ color: "#212529" }}>--</span>
                          )}
                        </td>
                        <td
                          className={
                            moment(data?.workedHours, "HH:mm").format("HH:mm") <
                            "08:00"
                              ? "red-col"
                              : "green-col"
                          }
                        >
                          {data?.workedHours ? (
                            getHours(data.workedHours)
                          ) : (
                            <span style={{ color: "#212529" }}>--</span>
                          )}
                        </td>
                        <td>
                          <span className="d-inline-block badge green-col">
                            Present
                          </span>
                        </td>
                        {permissionOnlyAdmin && (
                          <td>
                            <button
                              className="tb-action edit"
                              onClick={() => {
                                setVisible(true);
                                handleSetEditData(data);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </button>
                            <Popconfirm
                              title="Confirm Delete"
                              description="Are you sure you want to delete this attendance record?"
                              onConfirm={() => confirm(data._id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <button className="tb-action delete">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={18}
                                  height={18}
                                  fill="currentColor"
                                  className="bi bi-trash3"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg>
                              </button>
                            </Popconfirm>
                          </td>
                        )}
                      </tr>
                    );
                  } else {
                    const leaveDate = moment(data?.date);
                    return (
                      <tr key={data._id}>
                        <td>{leaveDate.format("D-M-yyyy")}</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>
                          <span className="d-inline-block badge red-col">
                            Leave
                          </span>
                        </td>
                        {permissionOnlyAdmin && (
                          <td>
                            <button
                              className="tb-action edit"
                              onClick={() => {
                                setLeaveVisible(true);
                                handleLeaveConvertData(data);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  }
                })
              )}
            </tbody>
          </table>
        </div>
        {/*.table-responsive*/}
        {visible && (
          <EditAttendanceModal
            visible={visible}
            setVisible={setVisible}
            data={editData}
            attId={attId}
            getData={() => setEditStatus(!editStatus)}
          />
        )}
        {leaveVisible && (
          <EditAttendanceModal
            leave
            visible={leaveVisible}
            setVisible={setLeaveVisible}
            data={editLeaveData}
            attId={leaveId}
            getData={() => setEditStatus(!editStatus)}
          />
        )}
      </div>
    </>
  );
}

export default AttendanceTable;
