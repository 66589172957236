import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import { ImageContext } from "../../../context/imageContext";

function SuperAdminNavigation() {
  const { logout, user } = useContext(AuthContext);

  const { profileImage } = useContext(ImageContext);

  return (
    <>
      <nav
        id="sidebarMenu"
        aria-labelledby="offcanvasExampleLabel"
        data-bs-scroll="true"
        tabIndex={-1}
        className="d-md-block bg-dark sidebar overflow-auto position-fixed bottom-0 left-0 px-0 offcanvas"
      >
        <div className="sidebar-inner">
          <ul className="nav flex-column" id="accordionExample">
            <li className="sidebar-user-panel text-center py-4 py-md-5">
              <div className="user-img-wrapper mb-2">
                <img
                  src={"/assets/images/AAMAX-Logo.jpg"}
                  className="rounded-pill user-img fit-user-img"
                  width={130}
                  height={130}
                  alt="Supper Admin"
                />
              </div>
              <div className="user-info">
                <h4 className="user-name text-white fs-5 fw-semibold mb-1">
                  Super Admin
                </h4>
                <span className="user-job-title text-muted">
                  {" "}
                  {user?.designation}
                </span>
              </div>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/admin/dashboard">
                <span className="align-text-bottom me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-columns-gap"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z" />
                  </svg>
                </span>
                Dashboard
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/admin/deleted-companies">
                <span className="align-text-bottom me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </span>
                Deleted Companies
              </NavLink>
            </li>

            <li className="nav-item">
              <a
                onClick={() => {
                  logout();
                }}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                <span className="align-text-bottom me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={15}
                    height={15}
                    fill="currentColor"
                    className="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                    />
                  </svg>
                </span>
                Logout
              </a>
            </li>
          </ul>
        </div>
        {/*.sidebar-inner*/}
      </nav>
    </>
  );
}

export default SuperAdminNavigation;
