import { Routes, Route } from "react-router-dom";
import Attendance from "./pages/employee/attendance";
import AttendanceRecord from "./pages/employee/attendanceRecord";
import Home from "./pages/employee/home";
import UserProfile from "./pages/employee/userProfile";
import ForgotPassword from "./pages/employee/forgotPassword";
import ProtectedRoute from "./components/protectedRoute";
import { AuthContext } from "./context/auth";
import { useContext, useEffect } from "react";
import setAuthToken from "./axios/axiosSetToken";
import { ImageContext } from "./context/imageContext";
import UpdatePassword from "./pages/employee/updatePassword";
import Dashboard from "./pages/admin/dashboard";
import AdminProfile from "./pages/admin/adminProfile";
import EmployeeAttendace from "./pages/admin/employeeAttendace";
import Employees from "./pages/admin/employees";
import PageNotFound from "./pages/404PageNotFound";
import Companies from "./pages/superAdmin/companies";
import DeletedCompanies from "./pages/superAdmin/deletedCompanies";

function App() {
  const { getUser, isAuthenticated, user } = useContext(AuthContext);
  const { getImage } = useContext(ImageContext);
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getUser(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route element={<ProtectedRoute />}>
        {
          user?.role === "employee" ? (
            <>
              <Route path="/attendance" element={<Attendance />} />
              <Route
                path="/attendance-history"
                element={<AttendanceRecord />}
              />
              <Route path="/profile" element={<UserProfile />} />
            </>
          ) : user?.role === "company" || user?.role === "manager" ? (
            <>
              <Route path="/admin-profile" element={<AdminProfile />} />
              <Route
                path="/employee-attendance"
                element={<EmployeeAttendace />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/employees" element={<Employees />} />
            </>
          ) : user?.role === "superAdmin" ? (
            <>
              <Route path="/admin/dashboard" element={<Companies />} />
              <Route
                path="/admin/deleted-companies"
                element={<DeletedCompanies />}
              />
            </>
          ) : null
          // <Route path="/attendance" element={<Attendance />} />
        }
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
