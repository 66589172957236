import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { Helmet } from "react-helmet";

function PageNotFound() {
  const token = localStorage.getItem("token");
  const { loading } = useContext(AuthContext);

  if (token && loading) {
    return <></>;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>404 – Page Not Found – AAMAX</title>
        </Helmet>
        {/*====================== Start 404 Error ======================*/}
        <section className="aamax-404 py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-xl-6 my-3 text-center text-md-start">
                <h1 className="display-1 mb-2 fw-semibold">404</h1>
                <h3 className="fs-1 mb-3">Page Not Found</h3>
                <p className="fs-5 mb-5 text-muted">
                  Uh oh, looks like you've stumbled onto a page that doesn't
                  exist. We apologize for any confusion or frustration this may
                  cause. Please use the link below to navigate to the homepage
                  of our app.
                </p>
                <Link
                  to="/"
                  className="btn primary-btn-two px-5 py-3 rounded-2 shadow"
                >
                  Back to Home
                </Link>
              </div>
              {/*.col-grid*/}
              <div className="col-md-6 my-3">
                <div className="text-center">
                  <img
                    src="assets/images/404-error.png"
                    className="img-fluid"
                    alt
                  />
                </div>
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container*/}
          <div className="circle position-absolute d-none d-md-block" />
          {/*.circle*/}
        </section>
        {/*====================== End 404 Error ======================*/}
      </div>
    </>
  );
}

export default PageNotFound;
