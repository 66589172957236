export const BASE_ROOT =
  process.env.REACT_APP_ENV === "production"
    ? "https://amsbe.aamax.co/api"
    : "http://localhost:8080/api";

// Auth
export const AUTH_LOGIN = "/auth/login";
export const AUTH_VERFIY_LINK_TOKEN = "/auth/verify-token";
export const AUTH_UPDATE_PASSWORD = "/auth/update-password";
export const AUTH_FORGET_PASSWORD = "/auth/forget-password";
export const USER = "/user";
export const USER_MY_INFO = "/user/getMyInfo";
export const USER_ATTENDANCE = "/user/attendance";
export const USER_THIS_MONTH_ATTENDANCE = "/user/this-month/attendance";
export const GET_EMPLOYEE = "/user/employee";

// Attendance
export const ATTENDANCE_BUTTON_STATUS = "/attendance/button-status";
export const CHECK_IN = "/attendance/checkin";
export const BREAK_START = "/attendance/breakstart";
export const BREAK_END = "/attendance/breakend";
export const CHECK_OUT = "/attendance/checkout";
export const DELETE_ATTENDANCE = "/attendance/delete";
export const UPDATE_ATTENDANCE = "/attendance";
export const UPDATE_ATTENDANCE_LEAVE = "/attendance/convert-leave";

// Leave
export const GET_LEAVES = "/leave/getall";

// Image
export const POST_IMAGE = "/image/url";
export const GET_IMAGE = "/image/url";
export const DELETE_IMAGE = "/image/url";
export const POST_USER_IMAGE = "/image/userimage";

// Admin
export const ADMIN_EMPLOYEES = "/user/employees";
export const ADMIN_COMPANIES = "/user/companies";
export const ADMIN_DELETED_COMPANIES = "/user/deleted-companies";
export const ADMIN_EMPLOYEES_TODAY_ATT = "/user/employees/att";
export const ADMIN_EMPLOYEES_MENU = "/user/employees/menu";
export const ADMIN_EMPLOYEES_EDIT = "/user/employee";
export const ADMIN_COMPANY_EDIT = "/user/company";

export const LOGIN_URL = `${BASE_ROOT}${AUTH_LOGIN}`;
export const VERFIY_LINK_TOKEN_URL = `${BASE_ROOT}${AUTH_VERFIY_LINK_TOKEN}`;
export const UPDATE_PASSWORD_URL = `${BASE_ROOT}${AUTH_UPDATE_PASSWORD}`;
export const FORGET_PASSWORD_URL = `${BASE_ROOT}${AUTH_FORGET_PASSWORD}`;
export const GET_ME_URL = `${BASE_ROOT}${USER}`;
export const USER_MY_INFO_URL = `${BASE_ROOT}${USER_MY_INFO}`;
export const ADD_USER_URL = `${BASE_ROOT}${USER}`;
export const EDIT_USER_URL = `${BASE_ROOT}${USER}`;
export const DELETE_USER_URL = `${BASE_ROOT}${USER}`;
export const GET_EMPLOYEE_URL = `${BASE_ROOT}${GET_EMPLOYEE}`;
export const ATTENDANCE_BUTTON_STATUS_URL = `${BASE_ROOT}${ATTENDANCE_BUTTON_STATUS}`;
export const USER_ATTENDANCE_URL = `${BASE_ROOT}${USER_ATTENDANCE}`;
export const USER_THIS_MONTH_ATTENDANCE_URL = `${BASE_ROOT}${USER_THIS_MONTH_ATTENDANCE}`;
export const DELETE_ATTENDANCE_URL = `${BASE_ROOT}${DELETE_ATTENDANCE}`;
export const UPDATE_ATTENDANCE_URL = `${BASE_ROOT}${UPDATE_ATTENDANCE}`;
export const UPDATE_ATTENDANCE_LEAVE_URL = `${BASE_ROOT}${UPDATE_ATTENDANCE_LEAVE}`;
export const CHECK_IN_URL = `${BASE_ROOT}${CHECK_IN}`;
export const BREAK_START_URL = `${BASE_ROOT}${BREAK_START}`;
export const BREAK_END_URL = `${BASE_ROOT}${BREAK_END}`;
export const CHECK_OUT_URL = `${BASE_ROOT}${CHECK_OUT}`;
export const GET_LEAVES_URL = `${BASE_ROOT}${GET_LEAVES}`;
export const POST_IMAGE_URL = `${BASE_ROOT}${POST_IMAGE}`;
export const GET_IMAGE_URL = `${BASE_ROOT}${GET_IMAGE}`;
export const POST_USER_IMAGE_URL = `${BASE_ROOT}${POST_USER_IMAGE}`;
export const DELETE_IMAGE_URL = `${BASE_ROOT}${DELETE_IMAGE}`;
export const ADMIN_EMPLOYEES_URL = `${BASE_ROOT}${ADMIN_EMPLOYEES}`;
export const ADMIN_COMPANIES_URL = `${BASE_ROOT}${ADMIN_COMPANIES}`;
export const ADMIN_DELETED_COMPANIES_URL = `${BASE_ROOT}${ADMIN_DELETED_COMPANIES}`;
export const ADMIN_EMPLOYEES_TODAY_ATT_URL = `${BASE_ROOT}${ADMIN_EMPLOYEES_TODAY_ATT}`;
export const ADMIN_EMPLOYEES_MENU_URL = `${BASE_ROOT}${ADMIN_EMPLOYEES_MENU}`;
export const ADMIN_EMPLOYEES_EDIT_URL = `${BASE_ROOT}${ADMIN_EMPLOYEES_EDIT}`;
export const ADMIN_COMPANY_EDIT_URL = `${BASE_ROOT}${ADMIN_COMPANY_EDIT}`;
