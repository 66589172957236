import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";

import {
  BREAK_START_URL,
  CHECK_IN_URL,
  BREAK_END_URL,
  CHECK_OUT_URL,
} from "../../endPoints";
import moment from "moment/moment";

function AttendanceButtons({
  buttonStatus,
  getButtonStatus,
  buttonStatusLoading,
  attendance,
  getAttendance,
}) {
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

  const handleCheckIn = async () => {
    setLoading(true);
    await axios
      .post(CHECK_IN_URL)
      .then((response) => {
        if (response.data?.status === 409) {
          message.warning(response.data.message);
        } else {
          message.success(response.data.message);
          setCurrentDate(moment().format("YYYY-MM-DD"));
          getButtonStatus();
          getAttendance();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleBreakStart = async () => {
    setLoading(true);
    await axios
      .patch(BREAK_START_URL)
      .then((response) => {
        if (response.data.message === "Error starting break") {
          message.warning(response.data.message);
        } else {
          message.success(response.data.message);
          setCurrentDate(moment().format("YYYY-MM-DD"));
          getButtonStatus();
          getAttendance();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleBreakEnd = async () => {
    setLoading(true);
    await axios
      .patch(BREAK_END_URL)
      .then((response) => {
        if (response.data.message === "Error ending break") {
          message.warning(response.data.message);
        } else {
          message.success(response.data.message);
          setCurrentDate(moment().format("YYYY-MM-DD"));
          getButtonStatus();
          getAttendance();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCheckOut = async () => {
    setLoading(true);
    await axios
      .patch(CHECK_OUT_URL)
      .then((response) => {
        if (response.data.message === "Error checking out") {
          message.warning(response.data.message);
        } else {
          message.success(response.data.message);
          setCurrentDate(moment().format("YYYY-MM-DD"));
          getButtonStatus();
          getAttendance();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="card-header p-3 p-md-4">
        <div className="row gx-xxl-5 justify-content-center">
          <div className="col-md-3 col-sm-6 text-center mt-3">
            <div className="atd-btn-wrapper mb-3 mb-md-4">
              <button
                className={`btn btn-lg primary-btn shadow-sm ${
                  !buttonStatus.checkIn && "active"
                }`}
                disabled={
                  buttonStatus.checkIn || loading || buttonStatusLoading
                }
                onClick={handleCheckIn}
              >
                <span className="icon align-text-bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </span>
                Check In
              </button>
            </div>
            <div className="today-atd-time">
              <h4 className="green-col">
                {attendance?.checkIn &&
                moment(attendance?.checkIn).format("YYYY-MM-DD") ===
                  currentDate ? (
                  moment(attendance?.checkIn).format("LT")
                ) : (
                  <span style={{ color: "#6c757d" }}>--</span>
                )}

                {/* {attendance?.checkIn ? (
                  moment(attendance?.checkIn).format("LT")
                ) : (
                  <span style={{ color: "#6c757d" }}>--</span>
                )} */}
              </h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 text-center mt-3">
            <div className="atd-btn-wrapper mb-3 mb-md-4">
              <button
                className={`btn btn-lg primary-btn shadow-sm ${
                  !buttonStatus.breakStart && "active"
                }`}
                disabled={
                  buttonStatus.breakStart || loading || buttonStatusLoading
                }
                onClick={handleBreakStart}
              >
                <span className="icon align-text-bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </span>
                Break Start
              </button>
            </div>
            <div className="today-atd-time">
              <h4>
                {attendance?.breaks?.length > 0 &&
                moment(
                  attendance?.breaks[attendance?.breaks.length - 1].start
                ).format("YYYY-MM-DD") === currentDate
                  ? moment(
                      attendance?.breaks[attendance?.breaks.length - 1].start
                    ).format("LT")
                  : "--"}

                {/* {attendance?.breaks?.length > 0
                  ? moment(
                      attendance.breaks[attendance.breaks.length - 1].start
                    ).format("LT")
                  : "--"} */}
              </h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 text-center mt-3">
            <div className="atd-btn-wrapper mb-3 mb-md-4">
              <button
                className={`btn btn-lg primary-btn shadow-sm ${
                  !buttonStatus.breakEnd && "active"
                }`}
                disabled={
                  buttonStatus.breakEnd || loading || buttonStatusLoading
                }
                onClick={handleBreakEnd}
              >
                <span className="icon align-text-bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </span>
                Break End
              </button>
            </div>
            <div className="today-atd-time">
              <h4>
                {attendance?.breaks?.length > 0 &&
                moment(
                  attendance?.breaks[attendance?.breaks.length - 1].end
                ).format("YYYY-MM-DD") === currentDate
                  ? moment(
                      attendance?.breaks[attendance?.breaks.length - 1].end
                    ).format("LT")
                  : "--"}

                {/* {attendance?.breaks?.length > 0 &&
                attendance.breaks[attendance.breaks.length - 1].end
                  ? moment(
                      attendance.breaks[attendance.breaks.length - 1].end
                    ).format("LT")
                  : "--"} */}
              </h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 text-center mt-3">
            <div className="atd-btn-wrapper mb-3 mb-md-4">
              <button
                className={`btn btn-lg primary-btn shadow-sm ${
                  !buttonStatus.checkOut && "active"
                }`}
                disabled={
                  buttonStatus.checkOut || loading || buttonStatusLoading
                }
                onClick={handleCheckOut}
              >
                <span className="icon align-text-bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </span>
                Check Out
              </button>
            </div>
            <div className="today-atd-time">
              <h4 className="red-col">
                {attendance?.checkOut &&
                moment(attendance?.checkOut).format("YYYY-MM-DD") ===
                  currentDate ? (
                  moment(attendance?.checkOut).format("LT")
                ) : (
                  <span style={{ color: "#6c757d" }}>--</span>
                )}

                {/* {attendance?.checkOut ? (
                  moment(attendance?.checkOut).format("LT")
                ) : (
                  <span style={{ color: "#6c757d" }}>--</span>
                )} */}
              </h4>
            </div>
          </div>
        </div>
      </div>
      {/*.card-header*/}
    </>
  );
}

export default AttendanceButtons;
