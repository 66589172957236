import React, { useContext, useEffect, useState } from "react";
import AttendanceButtons from "../../components/attendance/attendanceButtons";
import AttendanceDetail from "../../components/attendance/attendanceDetail";
import WorkingDetail from "../../components/attendance/workingDetail";
import MainLayout from "../../components/mainLayout";
import Navigation from "../../components/mainLayout/navigation";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  ATTENDANCE_BUTTON_STATUS_URL,
  USER_THIS_MONTH_ATTENDANCE_URL,
} from "../../endPoints";
import { AuthContext } from "../../context/auth";
import { getTodayDate } from "../../util/todayDate";
import { getCurrentMonth } from "../../util/currentMonth";

function Attendance() {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [attendances, setAttendances] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [buttonStatusLoading, setButtonStatusLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState({
    checkIn: true,
    breakStart: true,
    breakEnd: true,
    checkOut: true,
  });
  const [loading, setLoading] = useState(true);

  const getButtonStatus = () => {
    setButtonStatusLoading(true);
    axios
      .get(ATTENDANCE_BUTTON_STATUS_URL)
      .then((res) => {
        const status = res.data.data;
        setButtonStatus({
          checkIn: status.checkIn,
          breakStart: status.breakStart,
          breakEnd: status.breakEnd,
          checkOut: status.checkOut,
        });
        setAttendance(status?.attObject);
      })
      .then(() => {
        setButtonStatusLoading(false);
      });
  };
  const getData = () => {
    setLoading(true);
    axios.get(USER_THIS_MONTH_ATTENDANCE_URL).then((res) => {
      setAttendances(res.data.data);
      setLoading(false);
    });
  };

  const todayDate = getTodayDate();
  const currentMonth = getCurrentMonth();

  useEffect(() => {
    if (isAuthenticated) {
      getButtonStatus();
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div>
      <Helmet>
        <title>{currentMonth}, Attendance Overview – AAMAX</title>
      </Helmet>
      {/*====================== Start Dashboard ======================*/}
      <section className="ams-dashboard">
        <MainLayout />
        {/*.navbar*/}
        <div className="container-fluid p-0">
          <Navigation />
          {/*.sidebar*/}
          <main className="page-content-wrapper">
            <div className="page-content-inner">
              <div className="page-header d-flex justify-content-between flex-column flex-sm-row align-items-center py-3 mb-lg-2">
                <h4 className="my-2">{currentMonth}, Attendance Overview</h4>
                <div className="btn-toolbar my-2">
                  <h3 className="hd-date m-0">
                    Date : <small className="text-muted">{todayDate}</small>
                  </h3>
                </div>
              </div>
              {/*.page-header*/}
              <div className="row">
                <div className="col-12">
                  <div className="card-wrapper">
                    <AttendanceButtons
                      attendance={attendance}
                      buttonStatus={buttonStatus}
                      buttonStatusLoading={buttonStatusLoading}
                      getButtonStatus={getButtonStatus}
                      getAttendance={getData}
                    />
                    <AttendanceDetail
                      attendances={attendances}
                      loading={loading}
                    />
                    <WorkingDetail attendances={attendances} />
                  </div>
                  {/*.card-wrapper*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.page-content-inner*/}
          </main>
          {/*.page-content-wrapper*/}
        </div>
        {/*.container-fluid*/}
      </section>
      {/*====================== End Dashboard ======================*/}
    </div>
  );
}

export default Attendance;
