import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DashboardSkeleton({ withImg, num, length }) {
  return (
    <>
      {[...Array(length ? length : 5)].map((_) => (
        <tr>
          {withImg && (
            <td>
              <Skeleton className="table-img fit-user-img" />
            </td>
          )}
          {Array(num ? num : 7)
            .fill()
            .map((_, index) => (
              <td>
                <Skeleton height={25} width={"100%"} />
              </td>
            ))}
        </tr>
      ))}
    </>
  );
}

export default DashboardSkeleton;
