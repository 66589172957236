import moment from "moment";
import { getHours } from "../../util";
import DashboardSkeleton from "../dashboard/dashboardSkeleton";

function AttendanceDetail({ attendances, loading }) {
  return (
    <>
      <div className="card-body p-3 p-md-4">
        <div className="table-responsive h-limit">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Check In</th>
                <th scope="col">Break</th>
                <th scope="col">Check Out</th>
                <th scope="col">Time Worked</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <DashboardSkeleton num={6} />
              ) : (
                attendances?.map((data, i) => {
                  if (
                    (data.checkIn || data.checkOut) &&
                    data.status !== "leave"
                  ) {
                    return (
                      <tr key={i}>
                        <td>{moment(data?.date).format("D-M-yyyy")}</td>
                        <td
                          className={
                            moment.utc(data?.checkIn).local().format("HH:mm") >=
                            "10:00"
                              ? "red-col"
                              : "green-col"
                          }
                        >
                          {data?.checkIn
                            ? moment.utc(data?.checkIn).local().format("h:mm A")
                            : "--"}
                        </td>
                        <td>
                          {data?.breakTime ? getHours(data?.breakTime) : "--"}
                        </td>
                        <td className="green-col">
                          {data?.checkOut ? (
                            moment(data?.checkOut).format("LT")
                          ) : (
                            <span style={{ color: "#212529" }}>--</span>
                          )}
                        </td>
                        <td
                          className={
                            moment(data?.workedHours, "HH:mm").format("HH:mm") <
                            "08:00"
                              ? "red-col"
                              : "green-col"
                          }
                        >
                          {data?.workedHours ? (
                            getHours(data.workedHours)
                          ) : (
                            <span style={{ color: "#212529" }}>--</span>
                          )}
                        </td>
                        <td>
                          <span className="d-inline-block badge green-col">
                            Present
                          </span>
                        </td>
                      </tr>
                    );
                  } else {
                    const leaveDate = moment(data?.date);
                    return (
                      <tr key={i}>
                        <td>{leaveDate.format("D-M-yyyy")}</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>
                          <span className="d-inline-block badge red-col">
                            Leave
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })
              )}
            </tbody>
          </table>
        </div>
        {/*.table-responsive*/}
      </div>
      {/*.card-body*/}
    </>
  );
}

export default AttendanceDetail;
