import { message, Popconfirm } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { ADMIN_COMPANY_EDIT_URL } from "../../endPoints";
import DashboardSkeleton from "../dashboard/dashboardSkeleton";

function DeletedCompaniesTable({
  data,
  getData,
  searchEmpl,
  loading,
  page_size,
  nextPageCount,
}) {
  const confirm = (id) => {
    axios
      .patch(`${ADMIN_COMPANY_EDIT_URL}/${id}`)
      .then(() => {
        getData();
        message.success("Company restored successfully.");
      })

      .catch((err) => {
        message.warning("Failed to resotre comapny. Please try again.");
        console.error(err);
      });
  };

  const filteredEmployees = data.filter(
    (empl) =>
      empl.name.toLowerCase().includes(searchEmpl.toLowerCase()) ||
      empl.email.toLowerCase().includes(searchEmpl.toLowerCase())
  );

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover emp-attend-list">
          <thead>
            <tr>
              <th scope="col" className="img-col">
                Photo
              </th>
              <th scope="col">Name</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Email</th>
              <th scope="col">Employees</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <DashboardSkeleton
                withImg
                length={nextPageCount === 0 ? page_size : nextPageCount}
              />
            ) : (
              filteredEmployees.map((a) => {
                return (
                  <tr key={a._id}>
                    <td>
                      <img
                        src={a?.userId?.profile?.imageUrl}
                        className="table-img"
                        alt
                      />
                    </td>
                    <td>{a?.name}</td>
                    <td>{a?.phoneNumber}</td>
                    <td>{a?.email}</td>
                    <td>{a?.employeeCount || 0}</td>
                    <td>
                      {a?.userId.status === "active" ? (
                        <span className="d-inline-block badge green-col">
                          Active
                        </span>
                      ) : (
                        <span className="d-inline-block badge red-col">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>
                      <Popconfirm
                        title="Confirm Restore"
                        description="Are you sure you want to restore this company?"
                        onConfirm={() => confirm(a._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button
                          className="tb-action"
                          style={{
                            color: "#4db151",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            autorenew
                          </span>{" "}
                          Restore
                        </button>
                      </Popconfirm>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DeletedCompaniesTable;
