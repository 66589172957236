import React, { useContext } from "react";
import { AuthContext } from "../../context/auth";

function AdminInfo() {
  const { info, permissionOnlyAdmin } = useContext(AuthContext);

  return (
    <>
      <ul className="user-info m-0">
        <li className="border-0">
          <div className="title">Name :</div>
          <div className="text">{info?.name}</div>
        </li>
        <li className="border-0">
          <div className="title">Email :</div>
          <div className="text">{info?.email}</div>
        </li>

        <li className="border-0">
          <div className="title">Phone :</div>
          <div className="text">{info?.phoneNumber}</div>
        </li>
        {!permissionOnlyAdmin && (
          <li className="border-0">
            <div className="title">Designation :</div>
            <div className="text">{info?.designation}</div>
          </li>
        )}
        {/* <li className="border-0">
          <div className="title">Role :</div>
          <div className="text">{info?.role}</div>
        </li> */}
      </ul>
    </>
  );
}

export default AdminInfo;
