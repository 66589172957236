import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth";

function AdminLayout() {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
    $('.sidebar-toggle').click(function(){
      $('.ams-dashboard').toggleClass('sidebar-collapse');
    });
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <header className="navbar bg-black justify-content-start">
        <div className="logo-wrapper bg-dark ps-md-4 ps-3 pe-4 fs-6 rounded-0">
          <button
            className="navbar-toggler d-lg-none p-0 border-0 me-3"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <span className="nav-toggler-icon">
              <i className="bi bi-list" />
            </span>
          </button>
          <Link
            className="navbar-brand me-md-0 fs-6 pe-3 ms-auto me-auto ms-md-0 pe-lg-0"
            to="/"
          >
            <img
              src="/assets/images/logo-white.png"
              width="180px"
              className="img-fluid"
            />
          </Link>
        </div>
        {/*.logo-wrapper*/}
        <div className="text-start px-4 d-none d-md-flex align-items-center">
          <button
            className="sidebar-toggle border-0 bg-transparent d-none d-lg-block ps-0 me-2"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={28}
              height={28}
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          <h1 className="fs-3 text-white mb-0">Attendance Management System</h1>
        </div>
        {/*.header-heading*/}
        <ul className="navbar-nav nav-controls flex-row align-items-center ms-auto d-none d-lg-block">
          <li className="nav-item">
            <div className="nav-item">
              <a
                className="nav-link sign-out-act px-3 d-flex align-items-center"
                onClick={() => {
                  logout();
                }}
                style={{ cursor: "pointer" }}
              >
                <span className="align-text-bottom d-block me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className="bi bi-power"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg>
                </span>
                Logout
              </a>
            </div>
          </li>
        </ul>
        {/*.navbar-nav*/}
      </header>
    </>
  );
}

export default AdminLayout;
