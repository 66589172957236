import moment from "moment";

export const getHours = (hour) => {
  const duration = moment.duration(hour, "hours");

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return (
    <>
      {hours}:{minutes}
    </>
  );
};

export const debounce = (func, wait = 1000) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
