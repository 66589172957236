import React from "react";
import AdminLayout from "../../components/mainLayout/adminLayout";
import AdminNavigation from "../../components/mainLayout/navigation/adminNavigation";
import TodayAttendance from "../../components/dashboard/todayAttendance";
import { Helmet } from "react-helmet";

function Dashboard() {
  return (
    <div>
      <Helmet>
        <title>Admin Dashboard - AAMAX</title>
      </Helmet>

      {/*====================== Start Dashboard ======================*/}
      <section className="ams-dashboard admin-dashboard">
        <AdminLayout />
        {/*.navbar*/}
        <div className="container-fluid p-0">
          <AdminNavigation />
          {/*.sidebar*/}
          <TodayAttendance />
          {/*.page-content-wrapper*/}
        </div>
        {/*.container-fluid*/}
      </section>
      {/*====================== End Dashboard ======================*/}
    </div>
  );
}

export default Dashboard;
