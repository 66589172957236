import React, { useContext, useEffect } from "react";
import LogoSection from "../../components/login/logoSection";
import ForgotPassSec from "../../components/forgotPassword/forgotPassSec";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../context/auth";
import { Navigate } from "react-router-dom";
import axios from "axios";

function ForgotPassword() {
  const token = localStorage.getItem("token");
  const { isAuthenticated } = useContext(AuthContext);

  if (token || isAuthenticated) {
    return <Navigate to={"/attendance"} />;
  } else if (token) {
    return <></>;
  }
  return (
    <>
      <div>
        <Helmet>
          <title>Forgot Password – AAMAX</title>
        </Helmet>
        {/*====================== Start Forgot Form ======================*/}
        <section className="ams-login col-overlay py-5">
          <div className="container h-100">
            <div className="form-conainer-wrapper">
              <div className="form-container-inner shadow rounded-4 row gx-0 overflow-hidden w-100">
                <LogoSection />
                <ForgotPassSec />
              </div>
              {/*.row*/}
            </div>
            {/*.form-conainer-wrapper*/}
          </div>
          {/*.container*/}
        </section>
        {/*====================== End Forgot Form ======================*/}
      </div>
    </>
  );
}

export default ForgotPassword;
