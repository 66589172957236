import React, { useContext } from "react";
import UploadWidget from "./uploadWidget";

import { ImageContext } from "../../context/imageContext";
import { POST_IMAGE_URL } from "../../endPoints";
import axios from "axios";
import { AuthContext } from "../../context/auth";

function UserImage() {
  const { profileImage, deleteImage, getImage } = useContext(ImageContext);

  const handleImageUpload = (result) => {
    const imageUrl = result.info.secure_url;
    axios.post(POST_IMAGE_URL, { imageUrl }).then(() => {
      getImage();
    });
  };

  const handleDeleteImage = () => {
    deleteImage();
  };

  return (
    <>
      <div className="card-header p-3 p-md-4">
        <div className="d-flex flex-column flex-sm-row align-items-center pt-2">
          <div className="user-img-edit me-sm-3 mb-3 mb-sm-0">
            <div className="user-img-wrapper mb-2">
              <img
                src={profileImage || "assets/images/logo.png"}
                className="rounded-pill user-img fit-user-img"
                width={130}
                height={130}
                alt="user"
              />
            </div>
          </div>
          <div className="img-btn-act">
            <UploadWidget onUpload={handleImageUpload} />
            <button
              className="btn secondary-btn shadow-sm mx-md-2 mx-1"
              onClick={handleDeleteImage}
            >
              <span className="icon align-text-bottom me-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={15}
                  height={15}
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span>
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserImage;
