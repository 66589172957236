import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "../../util";
import axios from "axios";
import { ADMIN_EMPLOYEES_MENU_URL } from "../../endPoints";
import { AttContext } from "../../context/attContext";

const SearchField = ({ placeholder }) => {
  const { setEmployeeId } = useContext(AttContext);

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const resultContainer = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");

  const [data, setData] = useState([]);
  const getData = () => {
    axios.get(ADMIN_EMPLOYEES_MENU_URL).then((res) => {
      setData(res.data.data);
    });
  };

  const [filteredData, setFilteredData] = useState([]);

  const handleSelection = (selectedIndex) => {
    const selectedItem = filteredData[selectedIndex];
    if (!selectedItem) return resetSearchComplete();
    setEmployeeId(selectedItem._id);
    const line = `${selectedItem?.name}`;
    setDefaultValue(line);
    resetSearchComplete();
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleKeyDown = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    // move down
    if (key === "ArrowDown") nextIndexCount = (focusedIndex + 1) % data.length;

    // move up
    if (key === "ArrowUp")
      nextIndexCount = (focusedIndex + data.length - 1) % data.length;

    // hide search results
    if (key === "Escape") {
      resetSearchComplete();
    }

    // select the current item
    if (key === "Enter") {
      e.preventDefault();
      handleSelection(focusedIndex);
    }

    setFocusedIndex(nextIndexCount);
  };

  const filterData = ({ search_text }) => {
    const d = data.filter(
      (d) =>
        (d.name.toLowerCase().includes(search_text.toLowerCase()) ||
          d.email.toLowerCase().includes(search_text.toLowerCase())) &&
        d.role === "employee"
    );
    setFilteredData(d);
  };

  const callSearchAPI = debounce((obj) => filterData(obj), 350);

  const handleChange = (e) => {
    setDefaultValue(e.target.value);
    setShowResults(true);
    if (e.target.value) callSearchAPI({ search_text: e.target.value });
    else {
      setShowResults(false);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (!resultContainer.current) return;
    resultContainer.current.scrollIntoView({
      block: "center",
    });
  }, [focusedIndex]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="justify-content-center">
        <div
          className="searhFiled position-relative"
          onBlur={resetSearchComplete}
          onKeyDown={handleKeyDown}
        >
          <input
            type="text"
            className="form-control title-sm"
            placeholder={placeholder}
            value={defaultValue}
            onChange={handleChange}
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
          {showResults && (
            <div
              style={{ zIndex: 500, maxHeight: "180px", overflow: "auto" }}
              className="position-absolute mt-1 w-100 p-2 bg-white shadow-lg rounded-bottom rounded-top-end max-h-56 overflow-auto"
            >
              {filteredData?.map((e, index) => (
                <div
                  key={index}
                  ref={index === focusedIndex ? resultContainer : null}
                  style={{
                    backgroundColor:
                      index === focusedIndex ? "rgba(0,0,0,0.1)" : "",
                  }}
                  onMouseDown={() => handleSelection(index)}
                  className="cursor-pointer hover-bg-black hover-bg-opacity-10 p-2 fs-13 font-weight-medium"
                >
                  {e?.userId?.profile?.imageUrl ? (
                    <img
                      src={e?.userId?.profile?.imageUrl}
                      className="filter-dropdown-emp-img me-3 mb-3 mb-md-0 fit-user-img"
                      alt="Admin"
                    />
                  ) : (
                    <img
                      src={"assets/images/logo.png"}
                      className="filter-dropdown-emp-img me-3 mb-3 mb-md-0 fit-user-img"
                      alt="Admin"
                    />
                  )}
                  {`${e?.name}`}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchField;
